/* tslint:disable */
/* eslint-disable */
/**
 * Lifestack API (Staging)
 * This is the API documentation for Lifestack.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountAuthGenerateJWTPostRes
 */
export interface AccountAuthGenerateJWTPostRes {
    /**
     * refresh token
     * @type {string}
     * @memberof AccountAuthGenerateJWTPostRes
     */
    'refreshToken': string;
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthGenerateJWTPostRes
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface AccountAuthRefreshPostRes
 */
export interface AccountAuthRefreshPostRes {
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthRefreshPostRes
     */
    'access': string;
}
/**
 * 
 * @export
 * @interface AccountAuthSignInPostReq
 */
export interface AccountAuthSignInPostReq {
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'serverAuthCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAuthSignInPostReq
     */
    'tokenExpiryDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAuthSignInPostReq
     */
    'isNewTrialFlow'?: boolean;
    /**
     * FirestoreUser
     * @type {object}
     * @memberof AccountAuthSignInPostReq
     */
    'newUserInfo'?: object;
    /**
     * google scopes
     * @type {Array<string>}
     * @memberof AccountAuthSignInPostReq
     */
    'scopes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AccountAuthSignInPostRes
 */
export interface AccountAuthSignInPostRes {
    /**
     * User
     * @type {object}
     * @memberof AccountAuthSignInPostRes
     */
    'user': object;
    /**
     * refresh token
     * @type {string}
     * @memberof AccountAuthSignInPostRes
     */
    'refreshToken'?: string;
    /**
     * access token
     * @type {string}
     * @memberof AccountAuthSignInPostRes
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface AccountDevicesGetRes
 */
export interface AccountDevicesGetRes {
    /**
     * DevicePriorities
     * @type {object}
     * @memberof AccountDevicesGetRes
     */
    'sleepPriorities': object;
    /**
     * DevicePriorities
     * @type {object}
     * @memberof AccountDevicesGetRes
     */
    'stressPriorities': object;
    /**
     * Array<Device>
     * @type {Array<object>}
     * @memberof AccountDevicesGetRes
     */
    'devices': Array<object>;
    /**
     * Array of terraUserIds
     * @type {Array<string>}
     * @memberof AccountDevicesGetRes
     */
    'terraUserIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AccountSubscriptionGetRes
 */
export interface AccountSubscriptionGetRes {
    /**
     * Subscriptions
     * @type {Array<object>}
     * @memberof AccountSubscriptionGetRes
     */
    'subscriptions': Array<object>;
    /**
     * Active subscription
     * @type {object}
     * @memberof AccountSubscriptionGetRes
     */
    'activeSubscription'?: object;
    /**
     * Has access
     * @type {boolean}
     * @memberof AccountSubscriptionGetRes
     */
    'hasAccess': boolean;
}
/**
 * 
 * @export
 * @interface AvgEnergyDataTrendOfDayOfWeek
 */
export interface AvgEnergyDataTrendOfDayOfWeek {
    /**
     * 
     * @type {string}
     * @memberof AvgEnergyDataTrendOfDayOfWeek
     */
    'day': string;
    /**
     * 
     * @type {number}
     * @memberof AvgEnergyDataTrendOfDayOfWeek
     */
    'energy'?: number;
}
/**
 * 
 * @export
 * @interface CalcAIPlannerTimeFrameBody
 */
export interface CalcAIPlannerTimeFrameBody {
    /**
     * 
     * @type {number}
     * @memberof CalcAIPlannerTimeFrameBody
     */
    'targetPeakEnergy': number;
    /**
     * 
     * @type {CalcTimeFrame}
     * @memberof CalcAIPlannerTimeFrameBody
     */
    'originalDurations': CalcTimeFrame;
}
/**
 * 
 * @export
 * @interface CalcTimeFrame
 */
export interface CalcTimeFrame {
    /**
     * 
     * @type {number}
     * @memberof CalcTimeFrame
     */
    'deepworkDurationMin': number;
    /**
     * 
     * @type {number}
     * @memberof CalcTimeFrame
     */
    'breakDurationMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalcTimeFrame
     */
    'lunchDurationMin'?: number;
}
/**
 * 
 * @export
 * @interface CalendarAccess
 */
export interface CalendarAccess {
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAccess
     */
    'calendarWrite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAccess
     */
    'calendarRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAccess
     */
    'eventWrite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarAccess
     */
    'eventRead'?: boolean;
}
/**
 * 
 * @export
 * @interface CalendarError401Res
 */
export interface CalendarError401Res {
    /**
     * error: {message}
     * @type {string}
     * @memberof CalendarError401Res
     */
    'error': string;
    /**
     * -
     * @type {string}
     * @memberof CalendarError401Res
     */
    'rootEmail'?: string;
}
/**
 * 
 * @export
 * @interface CalendarEventPostReq
 */
export interface CalendarEventPostReq {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventPostReq
     */
    'taskId'?: string;
    /**
     * CalendarEventMetrics
     * @type {object}
     * @memberof CalendarEventPostReq
     */
    'metrics'?: object;
    /**
     * Partial<CalendarEvent>
     * @type {object}
     * @memberof CalendarEventPostReq
     */
    'newEventOption': object;
}
/**
 * 
 * @export
 * @interface CalendarEventsSyncRes
 */
export interface CalendarEventsSyncRes {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsSyncRes
     */
    'calendarId'?: string;
    /**
     * CalendarEvent[]
     * @type {Array<object>}
     * @memberof CalendarEventsSyncRes
     */
    'events'?: Array<object>;
}
/**
 * 
 * @export
 * @interface CalendarListSyncGCalReq
 */
export interface CalendarListSyncGCalReq {
    /**
     * Google refresh token
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'refreshToken'?: string;
    /**
     * Google access token
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'accessToken'?: string;
    /**
     * Google access token expiry date
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'expiryDate'?: string;
    /**
     * version string
     * @type {string}
     * @memberof CalendarListSyncGCalReq
     */
    'version'?: string;
    /**
     * google scopes
     * @type {Array<string>}
     * @memberof CalendarListSyncGCalReq
     */
    'scopes'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CalendarOutlookAuthTokenRes
 */
export interface CalendarOutlookAuthTokenRes {
    /**
     * Outlook refresh token
     * @type {string}
     * @memberof CalendarOutlookAuthTokenRes
     */
    'refreshToken'?: string;
    /**
     * Outlook access token
     * @type {string}
     * @memberof CalendarOutlookAuthTokenRes
     */
    'accessToken'?: string;
    /**
     * Outlook access token expiry date
     * @type {string}
     * @memberof CalendarOutlookAuthTokenRes
     */
    'expiryDate'?: string;
}
/**
 * 
 * @export
 * @interface CalendarWithAccess
 */
export interface CalendarWithAccess {
    /**
     * rootEmail
     * @type {string}
     * @memberof CalendarWithAccess
     */
    'rootEmail'?: string;
    /**
     * calendarType
     * @type {string}
     * @memberof CalendarWithAccess
     */
    'calendarType'?: string;
    /**
     * list of scopes
     * @type {Array<string>}
     * @memberof CalendarWithAccess
     */
    'scopes'?: Array<string>;
    /**
     * 
     * @type {CalendarAccess}
     * @memberof CalendarWithAccess
     */
    'access'?: CalendarAccess;
}
/**
 * 
 * @export
 * @interface ConfigureCalendlyRes
 */
export interface ConfigureCalendlyRes {
    /**
     * calendar integration id
     * @type {string}
     * @memberof ConfigureCalendlyRes
     */
    'calendlyIntegId'?: string;
}
/**
 * 
 * @export
 * @interface ConvertedReferralCampaign
 */
export interface ConvertedReferralCampaign {
    /**
     * campaignId
     * @type {string}
     * @memberof ConvertedReferralCampaign
     */
    'campaignId': string;
    /**
     * rewardfulClientId
     * @type {string}
     * @memberof ConvertedReferralCampaign
     */
    'rewardfulClientId'?: string;
}
/**
 * 
 * @export
 * @interface EnergyCircadianGetRes
 */
export interface EnergyCircadianGetRes {
    /**
     * Circadian rhythm
     * @type {Array<object>}
     * @memberof EnergyCircadianGetRes
     */
    'circadian': Array<object>;
    /**
     * Fluctuations
     * @type {object}
     * @memberof EnergyCircadianGetRes
     */
    'fluctuations': object;
}
/**
 * 
 * @export
 * @interface EnergyDataTrendWeeklyRes
 */
export interface EnergyDataTrendWeeklyRes {
    /**
     * 
     * @type {string}
     * @memberof EnergyDataTrendWeeklyRes
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof EnergyDataTrendWeeklyRes
     */
    'endDate': string;
    /**
     * EnergyDataTrend[]
     * @type {object}
     * @memberof EnergyDataTrendWeeklyRes
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof EnergyDataTrendWeeklyRes
     */
    'avgPeakEnergy': number;
    /**
     * 
     * @type {AvgEnergyDataTrendOfDayOfWeek}
     * @memberof EnergyDataTrendWeeklyRes
     */
    'avgEnergyOfDaysOfWeek': AvgEnergyDataTrendOfDayOfWeek;
}
/**
 * 
 * @export
 * @interface ErrorRes
 */
export interface ErrorRes {
    /**
     * error: {message}
     * @type {string}
     * @memberof ErrorRes
     */
    'error': string;
    /**
     * 
     * @type {string}
     * @memberof ErrorRes
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface EventDataTrendWeeklyRes
 */
export interface EventDataTrendWeeklyRes {
    /**
     * 
     * @type {string}
     * @memberof EventDataTrendWeeklyRes
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof EventDataTrendWeeklyRes
     */
    'endDate': string;
    /**
     * EventDataTrendEventItemWithData
     * @type {object}
     * @memberof EventDataTrendWeeklyRes
     */
    'stressfulEvent'?: object;
    /**
     * EventDataTrendEventItemWithData
     * @type {object}
     * @memberof EventDataTrendWeeklyRes
     */
    'energyDrainingEvent'?: object;
    /**
     * EventDataTrendEventItemWithData
     * @type {object}
     * @memberof EventDataTrendWeeklyRes
     */
    'energyBoostingEvent'?: object;
}
/**
 * 
 * @export
 * @interface ExportLifestackCalendarBody
 */
export interface ExportLifestackCalendarBody {
    /**
     * 
     * @type {Array<ExportLifestackCalendarParam>}
     * @memberof ExportLifestackCalendarBody
     */
    'calendars': Array<ExportLifestackCalendarParam>;
}
/**
 * 
 * @export
 * @interface ExportLifestackCalendarParam
 */
export interface ExportLifestackCalendarParam {
    /**
     * 
     * @type {string}
     * @memberof ExportLifestackCalendarParam
     */
    'calendarType': string;
    /**
     * 
     * @type {string}
     * @memberof ExportLifestackCalendarParam
     */
    'rootEmail': string;
}
/**
 * 
 * @export
 * @interface GetTerraUserStatusRes
 */
export interface GetTerraUserStatusRes {
    /**
     * 
     * @type {string}
     * @memberof GetTerraUserStatusRes
     */
    'terraUserId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTerraUserStatusRes
     */
    'valid': boolean;
    /**
     * -- TerraUserResponse
     * @type {object}
     * @memberof GetTerraUserStatusRes
     */
    'user': object;
}
/**
 * 
 * @export
 * @interface InitTodoPutRes
 */
export interface InitTodoPutRes {
    /**
     * 
     * @type {Array<string>}
     * @memberof InitTodoPutRes
     */
    'newTodoTasksBoardIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InitTodoPutRes
     */
    'newTodoIntegrationId': string;
}
/**
 * 
 * @export
 * @interface MetricsWebhookTerraPutReq
 */
export interface MetricsWebhookTerraPutReq {
    /**
     * TerraUser
     * @type {object}
     * @memberof MetricsWebhookTerraPutReq
     */
    'user': object;
    /**
     * 
     * @type {string}
     * @memberof MetricsWebhookTerraPutReq
     */
    'type': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof MetricsWebhookTerraPutReq
     */
    'data': Array<object>;
}
/**
 * 
 * @export
 * @interface RemoveCalendarsInfoBody
 */
export interface RemoveCalendarsInfoBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveCalendarsInfoBody
     */
    'calendarIds': Array<string>;
}
/**
 * 
 * @export
 * @interface RequestSubscriptionPostRes
 */
export interface RequestSubscriptionPostRes {
    /**
     * priceId
     * @type {string}
     * @memberof RequestSubscriptionPostRes
     */
    'priceId': string;
    /**
     * email
     * @type {string}
     * @memberof RequestSubscriptionPostRes
     */
    'email': string;
    /**
     * customerToken
     * @type {string}
     * @memberof RequestSubscriptionPostRes
     */
    'customerToken': string;
    /**
     * offeringId
     * @type {string}
     * @memberof RequestSubscriptionPostRes
     */
    'offeringId': string;
    /**
     * freeTrialDays
     * @type {number}
     * @memberof RequestSubscriptionPostRes
     */
    'freeTrialDays'?: number;
    /**
     * 
     * @type {ConvertedReferralCampaign}
     * @memberof RequestSubscriptionPostRes
     */
    'referral'?: ConvertedReferralCampaign;
    /**
     * Discount
     * @type {object}
     * @memberof RequestSubscriptionPostRes
     */
    'discount'?: object;
    /**
     * isRestored
     * @type {boolean}
     * @memberof RequestSubscriptionPostRes
     */
    'isRestored'?: boolean;
}
/**
 * 
 * @export
 * @interface SchedulerAITimeBoxPlannerPutFormattedRes
 */
export interface SchedulerAITimeBoxPlannerPutFormattedRes {
    /**
     * ScheduledActions[]
     * @type {Array<object>}
     * @memberof SchedulerAITimeBoxPlannerPutFormattedRes
     */
    'schedules': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof SchedulerAITimeBoxPlannerPutFormattedRes
     */
    'scienceApiReq'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerAITimeBoxPlannerPutFormattedRes
     */
    'scienceApiRes'?: string;
}
/**
 * 
 * @export
 * @interface SchedulerAITimeBoxPlannerPutReq
 */
export interface SchedulerAITimeBoxPlannerPutReq {
    /**
     * 
     * @type {string}
     * @memberof SchedulerAITimeBoxPlannerPutReq
     */
    'metricId': string;
    /**
     * 
     * @type {string}
     * @memberof SchedulerAITimeBoxPlannerPutReq
     */
    'currentTime': string;
    /**
     * SchedulerPreference
     * @type {object}
     * @memberof SchedulerAITimeBoxPlannerPutReq
     */
    'preferences': object;
}
/**
 * 
 * @export
 * @interface SchedulerEnergyBoostPutReq
 */
export interface SchedulerEnergyBoostPutReq {
    /**
     * CalendarEvent[]
     * @type {Array<object>}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'eventsOnDay': Array<object>;
    /**
     * PhaseStartEndSet
     * @type {object}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'circadianPhaseBoundaries': object;
    /**
     * 
     * @type {string}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'currentTime': string;
    /**
     * RecommendedTaskOrigin
     * @type {string}
     * @memberof SchedulerEnergyBoostPutReq
     */
    'origin'?: string;
}
/**
 * 
 * @export
 * @interface SleepDataTrendWeeklyRes
 */
export interface SleepDataTrendWeeklyRes {
    /**
     * 
     * @type {string}
     * @memberof SleepDataTrendWeeklyRes
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof SleepDataTrendWeeklyRes
     */
    'endDate': string;
    /**
     * SleepDetailsByDate[]
     * @type {object}
     * @memberof SleepDataTrendWeeklyRes
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof SleepDataTrendWeeklyRes
     */
    'avgDurationMin': number;
    /**
     * SleepStage
     * @type {object}
     * @memberof SleepDataTrendWeeklyRes
     */
    'avgStage': object;
}
/**
 * 
 * @export
 * @interface SuccessRes
 */
export interface SuccessRes {
    /**
     * success: true
     * @type {boolean}
     * @memberof SuccessRes
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface SyncExerciseBody
 */
export interface SyncExerciseBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncExerciseBody
     */
    'terraUserIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SyncExerciseBody
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof SyncExerciseBody
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface SyncMealBody
 */
export interface SyncMealBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncMealBody
     */
    'terraUserIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SyncMealBody
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof SyncMealBody
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface SyncSuccessRes
 */
export interface SyncSuccessRes {
    /**
     * success: true
     * @type {boolean}
     * @memberof SyncSuccessRes
     */
    'success': boolean;
    /**
     * SleepDetailsByDate[]
     * @type {Array<object>}
     * @memberof SyncSuccessRes
     */
    'results'?: Array<object>;
}
/**
 * 
 * @export
 * @interface TaskDataTrendWeeklyRes
 */
export interface TaskDataTrendWeeklyRes {
    /**
     * 
     * @type {string}
     * @memberof TaskDataTrendWeeklyRes
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDataTrendWeeklyRes
     */
    'endDate': string;
    /**
     * TaskDataTrendWithData[]
     * @type {object}
     * @memberof TaskDataTrendWeeklyRes
     */
    'data': object;
}
/**
 * 
 * @export
 * @interface TaskError401Res
 */
export interface TaskError401Res {
    /**
     * error: {message}
     * @type {string}
     * @memberof TaskError401Res
     */
    'error': string;
    /**
     * e.g., todoist, trello
     * @type {string}
     * @memberof TaskError401Res
     */
    'provider'?: string;
}
/**
 * 
 * @export
 * @interface TaskIdRes
 */
export interface TaskIdRes {
    /**
     * taskId: abcdef
     * @type {string}
     * @memberof TaskIdRes
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @interface TaskProjectIdRes
 */
export interface TaskProjectIdRes {
    /**
     * taskId: abcdef
     * @type {string}
     * @memberof TaskProjectIdRes
     */
    'taskProjectId': string;
}
/**
 * 
 * @export
 * @interface UpdateCannyPostRequest
 */
export interface UpdateCannyPostRequest {
    /**
     * Canny post ID
     * @type {string}
     * @memberof UpdateCannyPostRequest
     */
    'postId': string;
    /**
     * User ID
     * @type {string}
     * @memberof UpdateCannyPostRequest
     */
    'userId': string;
    /**
     * User name
     * @type {string}
     * @memberof UpdateCannyPostRequest
     */
    'userName': string;
    /**
     * User email
     * @type {string}
     * @memberof UpdateCannyPostRequest
     */
    'userEmail': string;
    /**
     * 
     * @type {UpdateCannyPostUpdates}
     * @memberof UpdateCannyPostRequest
     */
    'updates': UpdateCannyPostUpdates;
}
/**
 * 
 * @export
 * @interface UpdateCannyPostUpdates
 */
export interface UpdateCannyPostUpdates {
    /**
     * Vote
     * @type {boolean}
     * @memberof UpdateCannyPostUpdates
     */
    'vote'?: boolean;
    /**
     * Comment
     * @type {string}
     * @memberof UpdateCannyPostUpdates
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExerciseBody
 */
export interface UpdateExerciseBody {
    /**
     * ExerciseDetailsByDate[]
     * @type {Array<object>}
     * @memberof UpdateExerciseBody
     */
    'exerciseDetails': Array<object>;
}
/**
 * 
 * @export
 * @interface UpdateMealBody
 */
export interface UpdateMealBody {
    /**
     * MealDetailsByDate[]
     * @type {Array<object>}
     * @memberof UpdateMealBody
     */
    'mealDetails': Array<object>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStripeSubscription: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cancelStripeSubscription', 'userId', userId)
            const localVarPath = `/api/v1/account/subscriptions/stripe/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/api/v1/account/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('generateJwt', 'userId', userId)
            const localVarPath = `/api/v1/account/auth/generateJwt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} access 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSubscriptions: async (access: string, provider?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'access' is not null or undefined
            assertParamExists('getAvailableSubscriptions', 'access', access)
            const localVarPath = `/api/v1/account/subscriptions/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (access !== undefined) {
                localVarQueryParameter['access'] = access;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of devices
         * @param {string} deviceGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (deviceGroupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceGroupId' is not null or undefined
            assertParamExists('getDevices', 'deviceGroupId', deviceGroupId)
            const localVarPath = `/api/v1/account/devices/{deviceGroupId}`
                .replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(deviceGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get general preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getGeneralPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/account/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a referral campaign
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCampaign: async (campaignId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('getReferralCampaign', 'campaignId', campaignId)
            const localVarPath = `/api/v1/account/subscriptions/referral/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSubscriptions', 'userId', userId)
            const localVarPath = `/api/v1/account/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/api/v1/account/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a user token
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getuserToken: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getuserToken', 'userId', userId)
            const localVarPath = `/api/v1/account/user/{userId}/token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (refresh: string, withJwt?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refresh' is not null or undefined
            assertParamExists('refreshToken', 'refresh', refresh)
            const localVarPath = `/api/v1/account/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refresh !== undefined) {
                localVarQueryParameter['refresh'] = refresh;
            }

            if (withJwt !== undefined) {
                localVarQueryParameter['withJwt'] = withJwt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceV2: async (deviceGroupId: string, deviceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceGroupId' is not null or undefined
            assertParamExists('removeDeviceV2', 'deviceGroupId', deviceGroupId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('removeDeviceV2', 'deviceId', deviceId)
            const localVarPath = `/api/v1/account/devices/{deviceGroupId}/device/{deviceId}`
                .replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(deviceGroupId)))
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        removeUserDevice: async (userId: string, deviceTerraId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUserDevice', 'userId', userId)
            // verify required parameter 'deviceTerraId' is not null or undefined
            assertParamExists('removeUserDevice', 'deviceTerraId', deviceTerraId)
            const localVarPath = `/api/v1/account/user/{userId}/device`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deviceTerraId !== undefined) {
                localVarQueryParameter['deviceTerraId'] = deviceTerraId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {RequestSubscriptionPostRes} [requestSubscriptionPostRes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestStripeSubscription: async (userId: string, requestSubscriptionPostRes?: RequestSubscriptionPostRes, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestStripeSubscription', 'userId', userId)
            const localVarPath = `/api/v1/account/subscriptions/stripe/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestSubscriptionPostRes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('signIn', 'userId', userId)
            const localVarPath = `/api/v1/account/auth/signIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (withJwt !== undefined) {
                localVarQueryParameter['withJwt'] = withJwt;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountAuthSignInPostReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update general preferences
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;GeneralPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatGeneralPreference: async (preferenceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updatGeneralPreference', 'preferenceId', preferenceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatGeneralPreference', 'body', body)
            const localVarPath = `/api/v1/account/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {object} body Partial&lt;FirestoreDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceV2: async (deviceGroupId: string, deviceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceGroupId' is not null or undefined
            assertParamExists('updateDeviceV2', 'deviceGroupId', deviceGroupId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('updateDeviceV2', 'deviceId', deviceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDeviceV2', 'body', body)
            const localVarPath = `/api/v1/account/devices/{deviceGroupId}/device/{deviceId}`
                .replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(deviceGroupId)))
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update devices\' priorities
         * @param {string} deviceGroupId 
         * @param {string} metricSource MetricSourceType
         * @param {object} body DevicePriorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevicesPriorities: async (deviceGroupId: string, metricSource: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceGroupId' is not null or undefined
            assertParamExists('updateDevicesPriorities', 'deviceGroupId', deviceGroupId)
            // verify required parameter 'metricSource' is not null or undefined
            assertParamExists('updateDevicesPriorities', 'metricSource', metricSource)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDevicesPriorities', 'body', body)
            const localVarPath = `/api/v1/account/devices/{deviceGroupId}/priorities`
                .replace(`{${"deviceGroupId"}}`, encodeURIComponent(String(deviceGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metricSource !== undefined) {
                localVarQueryParameter['metricSource'] = metricSource;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingStatus: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateOnboardingStatus', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateOnboardingStatus', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}/onboarding`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSubscription', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateSubscription', 'body', body)
            const localVarPath = `/api/v1/account/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUser', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateUserDevices: async (userId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserDevices', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateUserDevices', 'requestBody', requestBody)
            const localVarPath = `/api/v1/account/user/{userId}/devices`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserToken', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserToken', 'body', body)
            const localVarPath = `/api/v1/account/user/{userId}/token`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate a referral code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        validateReferralCode: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('validateReferralCode', 'code', code)
            const localVarPath = `/api/v1/account/subscriptions/referral/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify a discount code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDiscountCode: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('verifyDiscountCode', 'code', code)
            const localVarPath = `/api/v1/account/subscriptions/discount/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to Stripe
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchStripeSubscriptionStatus: async (body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/subscriptions/stripe/watchStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchSubscriptionStatus: async (body?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/account/subscriptions/watchStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelStripeSubscription(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.cancelStripeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.deleteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateJwt(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthGenerateJWTPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateJwt(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.generateJwt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} access 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSubscriptions(access: string, provider?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSubscriptions(access, provider, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAvailableSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a list of devices
         * @param {string} deviceGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(deviceGroupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountDevicesGetRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(deviceGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getDevices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get general preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getGeneralPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a referral campaign
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReferralCampaign(campaignId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReferralCampaign(campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getReferralCampaign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptions(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountSubscriptionGetRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptions(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getSubscriptions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a user token
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getuserToken(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getuserToken(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getuserToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthRefreshPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(refresh, withJwt, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.refreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDeviceV2(deviceGroupId: string, deviceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDeviceV2(deviceGroupId, deviceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.removeDeviceV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserDevice(userId, deviceTerraId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.removeUserDevice']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {RequestSubscriptionPostRes} [requestSubscriptionPostRes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestStripeSubscription(userId: string, requestSubscriptionPostRes?: RequestSubscriptionPostRes, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestStripeSubscription(userId, requestSubscriptionPostRes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.requestStripeSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountAuthSignInPostRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(userId, withJwt, accountAuthSignInPostReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.signIn']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update general preferences
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;GeneralPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatGeneralPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatGeneralPreference(preferenceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updatGeneralPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {object} body Partial&lt;FirestoreDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDeviceV2(deviceGroupId: string, deviceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDeviceV2(deviceGroupId, deviceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateDeviceV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update devices\' priorities
         * @param {string} deviceGroupId 
         * @param {string} metricSource MetricSourceType
         * @param {object} body DevicePriorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDevicesPriorities(deviceGroupId: string, metricSource: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDevicesPriorities(deviceGroupId, metricSource, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateDevicesPriorities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOnboardingStatus(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateOnboardingStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscription(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateSubscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserDevices(userId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUserDevices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserToken(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateUserToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Validate a referral code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async validateReferralCode(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateReferralCode(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.validateReferralCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Verify a discount code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyDiscountCode(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyDiscountCode(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.verifyDiscountCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to Stripe
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchStripeSubscriptionStatus(body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchStripeSubscriptionStatus(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.watchStripeSubscriptionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.watchSubscriptionStatus(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.watchSubscriptionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel a subscription via Stripe
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.cancelStripeSubscription(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a new JWT
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthGenerateJWTPostRes> {
            return localVarFp.generateJwt(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available subscriptions
         * @param {string} access 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSubscriptions(access: string, provider?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAvailableSubscriptions(access, provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of devices
         * @param {string} deviceGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(deviceGroupId: string, options?: RawAxiosRequestConfig): AxiosPromise<AccountDevicesGetRes> {
            return localVarFp.getDevices(deviceGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get general preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getGeneralPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a referral campaign
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReferralCampaign(campaignId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getReferralCampaign(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptions(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<AccountSubscriptionGetRes> {
            return localVarFp.getSubscriptions(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a user token
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getuserToken(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getuserToken(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh an access token
         * @param {string} refresh 
         * @param {boolean} [withJwt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthRefreshPostRes> {
            return localVarFp.refreshToken(refresh, withJwt, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeviceV2(deviceGroupId: string, deviceId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.removeDeviceV2(deviceGroupId, deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a user device
         * @param {string} userId 
         * @param {string} deviceTerraId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.removeUserDevice(userId, deviceTerraId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a subscription via Stripe
         * @param {string} userId 
         * @param {RequestSubscriptionPostRes} [requestSubscriptionPostRes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestStripeSubscription(userId: string, requestSubscriptionPostRes?: RequestSubscriptionPostRes, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.requestStripeSubscription(userId, requestSubscriptionPostRes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign in or sign up a user
         * @param {string} userId 
         * @param {boolean} [withJwt] 
         * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig): AxiosPromise<AccountAuthSignInPostRes> {
            return localVarFp.signIn(userId, withJwt, accountAuthSignInPostReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update general preferences
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;GeneralPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatGeneralPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updatGeneralPreference(preferenceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a device
         * @param {string} deviceGroupId 
         * @param {string} deviceId 
         * @param {object} body Partial&lt;FirestoreDevice&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeviceV2(deviceGroupId: string, deviceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateDeviceV2(deviceGroupId, deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update devices\' priorities
         * @param {string} deviceGroupId 
         * @param {string} metricSource MetricSourceType
         * @param {object} body DevicePriorities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDevicesPriorities(deviceGroupId: string, metricSource: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateDevicesPriorities(deviceGroupId, metricSource, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an onboarding status
         * @param {string} userId 
         * @param {object} body Partial&lt;OnboardingStatus&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateOnboardingStatus(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a subscription
         * @param {string} userId 
         * @param {object} body Partial&lt;Subscription&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSubscription(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user info
         * @param {string} userId 
         * @param {object} body Parital&lt;FirestoreUser&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUser(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user devices
         * @param {string} userId 
         * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUserDevices(userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user token
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreToken&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateUserToken(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate a referral code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        validateReferralCode(code: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.validateReferralCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify a discount code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyDiscountCode(code: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.verifyDiscountCode(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to Stripe
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchStripeSubscriptionStatus(body?: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.watchStripeSubscriptionStatus(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Watch a subscription status -- Connect to RevenueCat
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.watchSubscriptionStatus(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Cancel a subscription via Stripe
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public cancelStripeSubscription(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).cancelStripeSubscription(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a user info
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public deleteUser(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a new JWT
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public generateJwt(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).generateJwt(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available subscriptions
     * @param {string} access 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAvailableSubscriptions(access: string, provider?: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAvailableSubscriptions(access, provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of devices
     * @param {string} deviceGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getDevices(deviceGroupId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getDevices(deviceGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get general preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getGeneralPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getGeneralPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a referral campaign
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getReferralCampaign(campaignId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getReferralCampaign(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscriptions
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getSubscriptions(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getSubscriptions(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user info
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getUser(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a user token
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getuserToken(userId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getuserToken(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh an access token
     * @param {string} refresh 
     * @param {boolean} [withJwt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public refreshToken(refresh: string, withJwt?: boolean, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).refreshToken(refresh, withJwt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a device
     * @param {string} deviceGroupId 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public removeDeviceV2(deviceGroupId: string, deviceId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).removeDeviceV2(deviceGroupId, deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a user device
     * @param {string} userId 
     * @param {string} deviceTerraId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public removeUserDevice(userId: string, deviceTerraId: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).removeUserDevice(userId, deviceTerraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a subscription via Stripe
     * @param {string} userId 
     * @param {RequestSubscriptionPostRes} [requestSubscriptionPostRes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public requestStripeSubscription(userId: string, requestSubscriptionPostRes?: RequestSubscriptionPostRes, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).requestStripeSubscription(userId, requestSubscriptionPostRes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign in or sign up a user
     * @param {string} userId 
     * @param {boolean} [withJwt] 
     * @param {AccountAuthSignInPostReq} [accountAuthSignInPostReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public signIn(userId: string, withJwt?: boolean, accountAuthSignInPostReq?: AccountAuthSignInPostReq, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).signIn(userId, withJwt, accountAuthSignInPostReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update general preferences
     * @param {string} preferenceId 
     * @param {object} body Partial&lt;GeneralPreference&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updatGeneralPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updatGeneralPreference(preferenceId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a device
     * @param {string} deviceGroupId 
     * @param {string} deviceId 
     * @param {object} body Partial&lt;FirestoreDevice&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateDeviceV2(deviceGroupId: string, deviceId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateDeviceV2(deviceGroupId, deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update devices\' priorities
     * @param {string} deviceGroupId 
     * @param {string} metricSource MetricSourceType
     * @param {object} body DevicePriorities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateDevicesPriorities(deviceGroupId: string, metricSource: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateDevicesPriorities(deviceGroupId, metricSource, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an onboarding status
     * @param {string} userId 
     * @param {object} body Partial&lt;OnboardingStatus&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateOnboardingStatus(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateOnboardingStatus(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a subscription
     * @param {string} userId 
     * @param {object} body Partial&lt;Subscription&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateSubscription(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateSubscription(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user info
     * @param {string} userId 
     * @param {object} body Parital&lt;FirestoreUser&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUser(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUser(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user devices
     * @param {string} userId 
     * @param {Array<object>} requestBody Array&lt;UserConnectedDevice&gt;
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUserDevices(userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUserDevices(userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user token
     * @param {string} userId 
     * @param {object} body Partial&lt;FirestoreToken&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateUserToken(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateUserToken(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate a referral code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public validateReferralCode(code: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).validateReferralCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify a discount code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public verifyDiscountCode(code: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).verifyDiscountCode(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Watch a subscription status -- Connect to Stripe
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public watchStripeSubscriptionStatus(body?: object, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).watchStripeSubscriptionStatus(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Watch a subscription status -- Connect to RevenueCat
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public watchSubscriptionStatus(body?: string, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).watchSubscriptionStatus(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a all-day calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllDayCalendarEvent: async (calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('createAllDayCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createAllDayCalendarEvent', 'userId', userId)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/allDay`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventPostReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarEvent: async (calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('createCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createCalendarEvent', 'userId', userId)
            const localVarPath = `/api/v1/calendar/events/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventPostReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {boolean} [unSyncToCalendar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEvent: async (userId: string, calendarId: string, eventId: string, unSyncToCalendar?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'userId', userId)
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteCalendarEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (unSyncToCalendar !== undefined) {
                localVarQueryParameter['unSyncToCalendar'] = unSyncToCalendar;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange outlook token
         * @param {string} userId 
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {Array<string>} [scopes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeOutlookToken: async (userId: string, code: string, redirectUri: string, version?: string, scopes?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('exchangeOutlookToken', 'userId', userId)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchangeOutlookToken', 'code', code)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('exchangeOutlookToken', 'redirectUri', redirectUri)
            const localVarPath = `/api/v1/calendar/auth/outlook/exchangeToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (scopes) {
                localVarQueryParameter['scopes[]'] = scopes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create energy calendar exported from Lifestack
         * @param {string} userId 
         * @param {string} preferenceId 
         * @param {string} metricId 
         * @param {ExportLifestackCalendarBody} exportLifestackCalendarBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportLifestackCalendar: async (userId: string, preferenceId: string, metricId: string, exportLifestackCalendarBody: ExportLifestackCalendarBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('exportLifestackCalendar', 'userId', userId)
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('exportLifestackCalendar', 'preferenceId', preferenceId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('exportLifestackCalendar', 'metricId', metricId)
            // verify required parameter 'exportLifestackCalendarBody' is not null or undefined
            assertParamExists('exportLifestackCalendar', 'exportLifestackCalendarBody', exportLifestackCalendarBody)
            const localVarPath = `/api/v1/calendar/external/energy/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (preferenceId !== undefined) {
                localVarQueryParameter['preferenceId'] = preferenceId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportLifestackCalendarBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate the url to authorize outlook
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutlookAuthUrl: async (redirectUri: string, version?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('generateOutlookAuthUrl', 'redirectUri', redirectUri)
            const localVarPath = `/api/v1/calendar/auth/outlook/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllDayCalendarEvents: async (userId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllDayCalendarEvents', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getAllDayCalendarEvents', 'date', date)
            const localVarPath = `/api/v1/calendar/events/allDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all-day calendar events (V2)
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDayCalendarEventsV2: async (userId: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAllDayCalendarEventsV2', 'userId', userId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllDayCalendarEventsV2', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllDayCalendarEventsV2', 'endDate', endDate)
            const localVarPath = `/api/v1/calendar/events/allDay/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar accesses
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarAccess: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCalendarAccess', 'userId', userId)
            const localVarPath = `/api/v1/calendar/auth/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvents: async (userId: string, startDate?: string, endDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCalendarEvents', 'userId', userId)
            const localVarPath = `/api/v1/calendar/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsForPhases: async (calendarIds: Array<string>, date: string, metricId: string, timezone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'calendarIds', calendarIds)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'date', date)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'metricId', metricId)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('getCalendarEventsForPhases', 'timezone', timezone)
            const localVarPath = `/api/v1/calendar/events/phase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get calendar preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getCalendarPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/calendar/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsInfo: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCalendarsInfo', 'userId', userId)
            const localVarPath = `/api/v1/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get daily event trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyEventTrend: async (dataTrendId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getDailyEventTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getDailyEventTrend', 'date', date)
            const localVarPath = `/api/v1/calendar/trend/{dataTrendId}/daily`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weekly event trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyEventTrend: async (dataTrendId: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getWeeklyEventTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWeeklyEventTrend', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWeeklyEventTrend', 'endDate', endDate)
            const localVarPath = `/api/v1/calendar/trend/{dataTrendId}/weekly`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Webhook for creating a new calendar event which comes from Cloud Run eventarc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onCreateCalendarEvent: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/calendar/webhook/event/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove calendars
         * @param {string} userId 
         * @param {RemoveCalendarsInfoBody} removeCalendarsInfoBody Calendar Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCalendarsInfo: async (userId: string, removeCalendarsInfoBody: RemoveCalendarsInfoBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeCalendarsInfo', 'userId', userId)
            // verify required parameter 'removeCalendarsInfoBody' is not null or undefined
            assertParamExists('removeCalendarsInfo', 'removeCalendarsInfoBody', removeCalendarsInfoBody)
            const localVarPath = `/api/v1/calendar/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeCalendarsInfoBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendarEvents: async (userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncCalendarEvents', 'userId', userId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('syncCalendarEvents', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('syncCalendarEvents', 'endTime', endTime)
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('syncCalendarEvents', 'calendarIds', calendarIds)
            const localVarPath = `/api/v1/calendar/events/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGCalendarsList: async (uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncGCalendarsList', 'uid', uid)
            // verify required parameter 'rootEmail' is not null or undefined
            assertParamExists('syncGCalendarsList', 'rootEmail', rootEmail)
            const localVarPath = `/api/v1/calendar/list/sync/gcal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (rootEmail !== undefined) {
                localVarQueryParameter['rootEmail'] = rootEmail;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarListSyncGCalReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncOutlookCalendarList: async (uid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncOutlookCalendarList', 'uid', uid)
            const localVarPath = `/api/v1/calendar/list/sync/outlook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOutlookCalendarListV2: async (uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('syncOutlookCalendarListV2', 'uid', uid)
            const localVarPath = `/api/v1/calendar/list/sync/outlook/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarListSyncGCalReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferences for calendar
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;CalendarPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatCalendarPreference: async (preferenceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updatCalendarPreference', 'preferenceId', preferenceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatCalendarPreference', 'body', body)
            const localVarPath = `/api/v1/calendar/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent: async (userId: string, calendarId: string, eventId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'userId', userId)
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'eventId', eventId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarEvent', 'body', body)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventEnergy: async (calendarId: string, eventId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'calendarId', calendarId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'eventId', eventId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarEventEnergy', 'body', body)
            const localVarPath = `/api/v1/calendar/events/{calendarId}/{eventId}/energy`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarInfo: async (calendarId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendarInfo', 'calendarId', calendarId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateCalendarInfo', 'body', body)
            const localVarPath = `/api/v1/calendar/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update exported calendar events
         * @param {string} userId 
         * @param {string} metricId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportedCalendarEvents: async (userId: string, metricId: string, body?: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateExportedCalendarEvents', 'userId', userId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateExportedCalendarEvents', 'metricId', metricId)
            const localVarPath = `/api/v1/calendar/external/energy/events/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update energy calendar settings
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;ExportedCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportedCalendarPreference: async (preferenceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updateExportedCalendarPreference', 'preferenceId', preferenceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateExportedCalendarPreference', 'body', body)
            const localVarPath = `/api/v1/calendar/external/energy/preference/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (preferenceId !== undefined) {
                localVarQueryParameter['preferenceId'] = preferenceId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a all-day calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAllDayCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAllDayCalendarEvent(calendarId, userId, calendarEventPostReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.createAllDayCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendarEvent(calendarId, userId, calendarEventPostReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.createCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {boolean} [unSyncToCalendar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEvent(userId: string, calendarId: string, eventId: string, unSyncToCalendar?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEvent(userId, calendarId, eventId, unSyncToCalendar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.deleteCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Exchange outlook token
         * @param {string} userId 
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {Array<string>} [scopes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeOutlookToken(userId: string, code: string, redirectUri: string, version?: string, scopes?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarOutlookAuthTokenRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeOutlookToken(userId, code, redirectUri, version, scopes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.exchangeOutlookToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create energy calendar exported from Lifestack
         * @param {string} userId 
         * @param {string} preferenceId 
         * @param {string} metricId 
         * @param {ExportLifestackCalendarBody} exportLifestackCalendarBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportLifestackCalendar(userId: string, preferenceId: string, metricId: string, exportLifestackCalendarBody: ExportLifestackCalendarBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportLifestackCalendar(userId, preferenceId, metricId, exportLifestackCalendarBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.exportLifestackCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate the url to authorize outlook
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateOutlookAuthUrl(redirectUri: string, version?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateOutlookAuthUrl(redirectUri, version, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.generateOutlookAuthUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDayCalendarEvents(userId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getAllDayCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all-day calendar events (V2)
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDayCalendarEventsV2(userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDayCalendarEventsV2(userId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getAllDayCalendarEventsV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get calendar accesses
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarAccess(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarWithAccess>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarAccess(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEvents(userId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarEventsForPhases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get calendar preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarsInfo(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getCalendarsInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get daily event trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyEventTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyEventTrend(dataTrendId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getDailyEventTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get weekly event trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklyEventTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventDataTrendWeeklyRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklyEventTrend(dataTrendId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.getWeeklyEventTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Webhook for creating a new calendar event which comes from Cloud Run eventarc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onCreateCalendarEvent(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onCreateCalendarEvent(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.onCreateCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove calendars
         * @param {string} userId 
         * @param {RemoveCalendarsInfoBody} removeCalendarsInfoBody Calendar Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeCalendarsInfo(userId: string, removeCalendarsInfoBody: RemoveCalendarsInfoBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeCalendarsInfo(userId, removeCalendarsInfoBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.removeCalendarsInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsSyncRes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCalendarEvents(userId, startTime, endTime, calendarIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncGCalendarsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOutlookCalendarList(uid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncOutlookCalendarList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.syncOutlookCalendarListV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preferences for calendar
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;CalendarPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatCalendarPreference(preferenceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updatCalendarPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEvent(userId, calendarId, eventId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEventEnergy(calendarId, eventId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarEventEnergy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarInfo(calendarId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateCalendarInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update exported calendar events
         * @param {string} userId 
         * @param {string} metricId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExportedCalendarEvents(userId: string, metricId: string, body?: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExportedCalendarEvents(userId, metricId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateExportedCalendarEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update energy calendar settings
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;ExportedCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExportedCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExportedCalendarPreference(preferenceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CalendarApi.updateExportedCalendarPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a all-day calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllDayCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.createAllDayCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a calendar event
         * @param {string} calendarId 
         * @param {string} userId 
         * @param {CalendarEventPostReq} [calendarEventPostReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.createCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {boolean} [unSyncToCalendar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEvent(userId: string, calendarId: string, eventId: string, unSyncToCalendar?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteCalendarEvent(userId, calendarId, eventId, unSyncToCalendar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange outlook token
         * @param {string} userId 
         * @param {string} code 
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {Array<string>} [scopes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeOutlookToken(userId: string, code: string, redirectUri: string, version?: string, scopes?: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<CalendarOutlookAuthTokenRes> {
            return localVarFp.exchangeOutlookToken(userId, code, redirectUri, version, scopes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create energy calendar exported from Lifestack
         * @param {string} userId 
         * @param {string} preferenceId 
         * @param {string} metricId 
         * @param {ExportLifestackCalendarBody} exportLifestackCalendarBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportLifestackCalendar(userId: string, preferenceId: string, metricId: string, exportLifestackCalendarBody: ExportLifestackCalendarBody, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.exportLifestackCalendar(userId, preferenceId, metricId, exportLifestackCalendarBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate the url to authorize outlook
         * @param {string} redirectUri 
         * @param {string} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateOutlookAuthUrl(redirectUri: string, version?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.generateOutlookAuthUrl(redirectUri, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all-day calendar events
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAllDayCalendarEvents(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all-day calendar events (V2)
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDayCalendarEventsV2(userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAllDayCalendarEventsV2(userId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar accesses
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarAccess(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<CalendarWithAccess>> {
            return localVarFp.getCalendarAccess(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar events
         * @param {string} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarEvents(userId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get events in each ciradian phase
         * @param {Array<string>} calendarIds 
         * @param {string} date 
         * @param {string} metricId 
         * @param {string} timezone 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get calendar preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getCalendarPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all calendars
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getCalendarsInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get daily event trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyEventTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getDailyEventTrend(dataTrendId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weekly event trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyEventTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<EventDataTrendWeeklyRes> {
            return localVarFp.getWeeklyEventTrend(dataTrendId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Webhook for creating a new calendar event which comes from Cloud Run eventarc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onCreateCalendarEvent(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.onCreateCalendarEvent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove calendars
         * @param {string} userId 
         * @param {RemoveCalendarsInfoBody} removeCalendarsInfoBody Calendar Ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeCalendarsInfo(userId: string, removeCalendarsInfoBody: RemoveCalendarsInfoBody, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.removeCalendarsInfo(userId, removeCalendarsInfoBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync calendar events
         * @param {string} userId 
         * @param {string} startTime 
         * @param {string} endTime 
         * @param {Array<string>} calendarIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<CalendarEventsSyncRes>> {
            return localVarFp.syncCalendarEvents(userId, startTime, endTime, calendarIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Google Calendar list
         * @param {string} uid 
         * @param {string} rootEmail 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Outlook Calendar list
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncOutlookCalendarList(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync Outlook Calendar list V2
         * @param {string} uid 
         * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferences for calendar
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;CalendarPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updatCalendarPreference(preferenceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a calendar event
         * @param {string} userId 
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body Partial&lt;CalendarEvent&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.updateCalendarEvent(userId, calendarId, eventId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update EMA energy for a calendar event
         * @param {string} calendarId 
         * @param {string} eventId 
         * @param {object} body CalendarEventMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateCalendarEventEnergy(calendarId, eventId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a calendar info
         * @param {string} calendarId 
         * @param {object} body Partial&lt;FirestoreCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateCalendarInfo(calendarId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update exported calendar events
         * @param {string} userId 
         * @param {string} metricId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportedCalendarEvents(userId: string, metricId: string, body?: object, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.updateExportedCalendarEvents(userId, metricId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update energy calendar settings
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;ExportedCalendar&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExportedCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateExportedCalendarPreference(preferenceId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @summary Create a all-day calendar event
     * @param {string} calendarId 
     * @param {string} userId 
     * @param {CalendarEventPostReq} [calendarEventPostReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createAllDayCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).createAllDayCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a calendar event
     * @param {string} calendarId 
     * @param {string} userId 
     * @param {CalendarEventPostReq} [calendarEventPostReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createCalendarEvent(calendarId: string, userId: string, calendarEventPostReq?: CalendarEventPostReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).createCalendarEvent(calendarId, userId, calendarEventPostReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete a calendar event
     * @param {string} userId 
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {boolean} [unSyncToCalendar] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendarEvent(userId: string, calendarId: string, eventId: string, unSyncToCalendar?: boolean, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteCalendarEvent(userId, calendarId, eventId, unSyncToCalendar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange outlook token
     * @param {string} userId 
     * @param {string} code 
     * @param {string} redirectUri 
     * @param {string} [version] 
     * @param {Array<string>} [scopes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public exchangeOutlookToken(userId: string, code: string, redirectUri: string, version?: string, scopes?: Array<string>, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).exchangeOutlookToken(userId, code, redirectUri, version, scopes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create energy calendar exported from Lifestack
     * @param {string} userId 
     * @param {string} preferenceId 
     * @param {string} metricId 
     * @param {ExportLifestackCalendarBody} exportLifestackCalendarBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public exportLifestackCalendar(userId: string, preferenceId: string, metricId: string, exportLifestackCalendarBody: ExportLifestackCalendarBody, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).exportLifestackCalendar(userId, preferenceId, metricId, exportLifestackCalendarBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate the url to authorize outlook
     * @param {string} redirectUri 
     * @param {string} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public generateOutlookAuthUrl(redirectUri: string, version?: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).generateOutlookAuthUrl(redirectUri, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all-day calendar events
     * @param {string} userId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAllDayCalendarEvents(userId: string, date: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getAllDayCalendarEvents(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all-day calendar events (V2)
     * @param {string} userId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getAllDayCalendarEventsV2(userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getAllDayCalendarEventsV2(userId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar accesses
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarAccess(userId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarAccess(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar events
     * @param {string} userId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEvents(userId: string, startDate?: string, endDate?: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarEvents(userId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get events in each ciradian phase
     * @param {Array<string>} calendarIds 
     * @param {string} date 
     * @param {string} metricId 
     * @param {string} timezone 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEventsForPhases(calendarIds: Array<string>, date: string, metricId: string, timezone: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarEventsForPhases(calendarIds, date, metricId, timezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get calendar preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all calendars
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarsInfo(userId: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarsInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get daily event trend
     * @param {string} dataTrendId 
     * @param {string} date YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDailyEventTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getDailyEventTrend(dataTrendId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weekly event trend
     * @param {string} dataTrendId 
     * @param {string} startDate YYYY-MM-DD
     * @param {string} endDate YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getWeeklyEventTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getWeeklyEventTrend(dataTrendId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Webhook for creating a new calendar event which comes from Cloud Run eventarc.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public onCreateCalendarEvent(options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).onCreateCalendarEvent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove calendars
     * @param {string} userId 
     * @param {RemoveCalendarsInfoBody} removeCalendarsInfoBody Calendar Ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public removeCalendarsInfo(userId: string, removeCalendarsInfoBody: RemoveCalendarsInfoBody, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).removeCalendarsInfo(userId, removeCalendarsInfoBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync calendar events
     * @param {string} userId 
     * @param {string} startTime 
     * @param {string} endTime 
     * @param {Array<string>} calendarIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncCalendarEvents(userId: string, startTime: string, endTime: string, calendarIds: Array<string>, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncCalendarEvents(userId, startTime, endTime, calendarIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Google Calendar list
     * @param {string} uid 
     * @param {string} rootEmail 
     * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncGCalendarsList(uid: string, rootEmail: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncGCalendarsList(uid, rootEmail, calendarListSyncGCalReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Outlook Calendar list
     * @param {string} uid 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncOutlookCalendarList(uid: string, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncOutlookCalendarList(uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync Outlook Calendar list V2
     * @param {string} uid 
     * @param {CalendarListSyncGCalReq} [calendarListSyncGCalReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public syncOutlookCalendarListV2(uid: string, calendarListSyncGCalReq?: CalendarListSyncGCalReq, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).syncOutlookCalendarListV2(uid, calendarListSyncGCalReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferences for calendar
     * @param {string} preferenceId 
     * @param {object} body Partial&lt;CalendarPreference&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updatCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updatCalendarPreference(preferenceId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a calendar event
     * @param {string} userId 
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {object} body Partial&lt;CalendarEvent&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarEvent(userId: string, calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarEvent(userId, calendarId, eventId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update EMA energy for a calendar event
     * @param {string} calendarId 
     * @param {string} eventId 
     * @param {object} body CalendarEventMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarEventEnergy(calendarId: string, eventId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarEventEnergy(calendarId, eventId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a calendar info
     * @param {string} calendarId 
     * @param {object} body Partial&lt;FirestoreCalendar&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateCalendarInfo(calendarId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateCalendarInfo(calendarId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update exported calendar events
     * @param {string} userId 
     * @param {string} metricId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateExportedCalendarEvents(userId: string, metricId: string, body?: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateExportedCalendarEvents(userId, metricId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update energy calendar settings
     * @param {string} preferenceId 
     * @param {object} body Partial&lt;ExportedCalendar&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public updateExportedCalendarPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig) {
        return CalendarApiFp(this.configuration).updateExportedCalendarPreference(preferenceId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DevApi - axios parameter creator
 * @export
 */
export const DevApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAnalytics: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('runAnalytics', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('runAnalytics', 'endDate', endDate)
            const localVarPath = `/api/v1/dev/analytics/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevApi - functional programming interface
 * @export
 */
export const DevApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runAnalytics(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DevApi.runAnalytics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DevApi - factory interface
 * @export
 */
export const DevApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevApiFp(configuration)
    return {
        /**
         * 
         * @summary Run analytics
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.runAnalytics(startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevApi - object-oriented interface
 * @export
 * @class DevApi
 * @extends {BaseAPI}
 */
export class DevApi extends BaseAPI {
    /**
     * 
     * @summary Run analytics
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevApi
     */
    public runAnalytics(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return DevApiFp(this.configuration).runAnalytics(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnergyApi - axios parameter creator
 * @export
 */
export const EnergyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgCircadian: async (userId: string, metricId: string, referenceDate: string, chronotype?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAvgCircadian', 'userId', userId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getAvgCircadian', 'metricId', metricId)
            // verify required parameter 'referenceDate' is not null or undefined
            assertParamExists('getAvgCircadian', 'referenceDate', referenceDate)
            const localVarPath = `/api/v1/energy/circadian/avg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (referenceDate !== undefined) {
                localVarQueryParameter['referenceDate'] = referenceDate;
            }

            if (chronotype !== undefined) {
                localVarQueryParameter['chronotype'] = chronotype;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [enableStressCombine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCircadian: async (userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, enableStressCombine?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCircadian', 'userId', userId)
            // verify required parameter 'calendarIds' is not null or undefined
            assertParamExists('getCircadian', 'calendarIds', calendarIds)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getCircadian', 'metricId', metricId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getCircadian', 'date', date)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('getCircadian', 'timezone', timezone)
            const localVarPath = `/api/v1/energy/circadian`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (calendarIds) {
                localVarQueryParameter['calendarIds[]'] = calendarIds;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (enableStressCombine !== undefined) {
                localVarQueryParameter['enableStressCombine'] = enableStressCombine;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get daily energy trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyEnergyTrend: async (dataTrendId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getDailyEnergyTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getDailyEnergyTrend', 'date', date)
            const localVarPath = `/api/v1/energy/trend/{dataTrendId}/daily`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getEnergyPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/energy/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseEmas: async (userId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPhaseEmas', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getPhaseEmas', 'date', date)
            const localVarPath = `/api/v1/energy/ema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weekly energy trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyEnergyTrend: async (dataTrendId: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getWeeklyEnergyTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWeeklyEnergyTrend', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWeeklyEnergyTrend', 'endDate', endDate)
            const localVarPath = `/api/v1/energy/trend/{dataTrendId}/weekly`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventCategoryPreference: async (preferenceId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updateEventCategoryPreference', 'preferenceId', preferenceId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateEventCategoryPreference', 'requestBody', requestBody)
            const localVarPath = `/api/v1/energy/preference/{preferenceId}/eventCategory`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhaseEma: async (userId: string, date: string, timezone: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updatePhaseEma', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('updatePhaseEma', 'date', date)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('updatePhaseEma', 'timezone', timezone)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updatePhaseEma', 'body', body)
            const localVarPath = `/api/v1/energy/ema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnergyApi - functional programming interface
 * @export
 */
export const EnergyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnergyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvgCircadian(userId, metricId, referenceDate, chronotype, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getAvgCircadian']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [enableStressCombine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, enableStressCombine?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyCircadianGetRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCircadian(userId, calendarIds, metricId, date, timezone, enableStressCombine, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getCircadian']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get daily energy trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyEnergyTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyEnergyTrend(dataTrendId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getDailyEnergyTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getEnergyPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhaseEmas(userId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getPhaseEmas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get weekly energy trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklyEnergyTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnergyDataTrendWeeklyRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklyEnergyTrend(dataTrendId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.getWeeklyEnergyTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEventCategoryPreference(preferenceId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.updateEventCategoryPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePhaseEma(userId, date, timezone, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnergyApi.updatePhaseEma']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnergyApi - factory interface
 * @export
 */
export const EnergyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnergyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get average circadian rhythm
         * @param {string} userId 
         * @param {string} metricId 
         * @param {string} referenceDate 
         * @param {string} [chronotype] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAvgCircadian(userId, metricId, referenceDate, chronotype, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get circadian rhythm
         * @param {string} userId 
         * @param {Array<string>} calendarIds 
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {boolean} [enableStressCombine] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, enableStressCombine?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<EnergyCircadianGetRes> {
            return localVarFp.getCircadian(userId, calendarIds, metricId, date, timezone, enableStressCombine, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get daily energy trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyEnergyTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getDailyEnergyTrend(dataTrendId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get energy preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEnergyPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getPhaseEmas(userId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weekly energy trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyEnergyTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<EnergyDataTrendWeeklyRes> {
            return localVarFp.getWeeklyEnergyTrend(dataTrendId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferences for event categoiries\' energy
         * @param {string} preferenceId 
         * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateEventCategoryPreference(preferenceId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a phase ema
         * @param {string} userId 
         * @param {string} date 
         * @param {string} timezone 
         * @param {object} body EMADatePhaseScoreSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updatePhaseEma(userId, date, timezone, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnergyApi - object-oriented interface
 * @export
 * @class EnergyApi
 * @extends {BaseAPI}
 */
export class EnergyApi extends BaseAPI {
    /**
     * 
     * @summary Get average circadian rhythm
     * @param {string} userId 
     * @param {string} metricId 
     * @param {string} referenceDate 
     * @param {string} [chronotype] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getAvgCircadian(userId: string, metricId: string, referenceDate: string, chronotype?: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getAvgCircadian(userId, metricId, referenceDate, chronotype, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get circadian rhythm
     * @param {string} userId 
     * @param {Array<string>} calendarIds 
     * @param {string} metricId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {boolean} [enableStressCombine] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getCircadian(userId: string, calendarIds: Array<string>, metricId: string, date: string, timezone: string, enableStressCombine?: boolean, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getCircadian(userId, calendarIds, metricId, date, timezone, enableStressCombine, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get daily energy trend
     * @param {string} dataTrendId 
     * @param {string} date YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getDailyEnergyTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getDailyEnergyTrend(dataTrendId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get energy preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getEnergyPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getEnergyPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a phase ema
     * @param {string} userId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getPhaseEmas(userId: string, date: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getPhaseEmas(userId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weekly energy trend
     * @param {string} dataTrendId 
     * @param {string} startDate YYYY-MM-DD
     * @param {string} endDate YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public getWeeklyEnergyTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).getWeeklyEnergyTrend(dataTrendId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferences for event categoiries\' energy
     * @param {string} preferenceId 
     * @param {Array<object>} requestBody EventCategoryEnergyPreference[]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public updateEventCategoryPreference(preferenceId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).updateEventCategoryPreference(preferenceId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a phase ema
     * @param {string} userId 
     * @param {string} date 
     * @param {string} timezone 
     * @param {object} body EMADatePhaseScoreSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnergyApi
     */
    public updatePhaseEma(userId: string, date: string, timezone: string, body: object, options?: RawAxiosRequestConfig) {
        return EnergyApiFp(this.configuration).updatePhaseEma(userId, date, timezone, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExternalApi - axios parameter creator
 * @export
 */
export const ExternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Canny post
         * @param {UpdateCannyPostRequest} updateCannyPostRequest Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCannyPost: async (updateCannyPostRequest: UpdateCannyPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCannyPostRequest' is not null or undefined
            assertParamExists('updateCannyPost', 'updateCannyPostRequest', updateCannyPostRequest)
            const localVarPath = `/api/v1/external/canny`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCannyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackProfileStatus: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateSlackProfileStatus', 'code', code)
            const localVarPath = `/api/v1/external/slack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalApi - functional programming interface
 * @export
 */
export const ExternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Canny post
         * @param {UpdateCannyPostRequest} updateCannyPostRequest Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCannyPost(updateCannyPostRequest: UpdateCannyPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCannyPost(updateCannyPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.updateCannyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlackProfileStatus(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExternalApi.updateSlackProfileStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExternalApi - factory interface
 * @export
 */
export const ExternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Canny post
         * @param {UpdateCannyPostRequest} updateCannyPostRequest Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCannyPost(updateCannyPostRequest: UpdateCannyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateCannyPost(updateCannyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a slack profile status based on energy levels
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSlackProfileStatus(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalApi - object-oriented interface
 * @export
 * @class ExternalApi
 * @extends {BaseAPI}
 */
export class ExternalApi extends BaseAPI {
    /**
     * 
     * @summary Update Canny post
     * @param {UpdateCannyPostRequest} updateCannyPostRequest Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public updateCannyPost(updateCannyPostRequest: UpdateCannyPostRequest, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).updateCannyPost(updateCannyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a slack profile status based on energy levels
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalApi
     */
    public updateSlackProfileStatus(code: string, options?: RawAxiosRequestConfig) {
        return ExternalApiFp(this.configuration).updateSlackProfileStatus(code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationsApi - axios parameter creator
 * @export
 */
export const IntegrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Configure Calendly integration
         * @param {string} userId 
         * @param {object} body  Partial&lt;FirestoreCalendlyIntegration&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureCalendly: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('configureCalendly', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('configureCalendly', 'body', body)
            const localVarPath = `/api/v1/integrations/calendly/configure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Exchange code for todo integrations
         * @param {string} code 
         * @param {string} todoType TodoIntegrationType
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeCode: async (code: string, todoType: string, redirectUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchangeCode', 'code', code)
            // verify required parameter 'todoType' is not null or undefined
            assertParamExists('exchangeCode', 'todoType', todoType)
            const localVarPath = `/api/v1/integrations/todo/exchangeCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirectUrl'] = redirectUrl;
            }

            if (todoType !== undefined) {
                localVarQueryParameter['todoType'] = todoType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTerraWidget: async (metricId: string, redirectTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('generateTerraWidget', 'metricId', metricId)
            const localVarPath = `/api/v1/integrations/terra/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }

            if (redirectTo !== undefined) {
                localVarQueryParameter['redirectTo'] = redirectTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTodoAuthUrl: async (todoType: string, callback?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoType' is not null or undefined
            assertParamExists('generateTodoAuthUrl', 'todoType', todoType)
            const localVarPath = `/api/v1/integrations/todo/generateAuthUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoType !== undefined) {
                localVarQueryParameter['todoType'] = todoType;
            }

            if (callback !== undefined) {
                localVarQueryParameter['callback'] = callback;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Calendly integration info
         * @param {string} calendlyIntegId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendlyIntegInfo: async (calendlyIntegId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendlyIntegId' is not null or undefined
            assertParamExists('getCalendlyIntegInfo', 'calendlyIntegId', calendlyIntegId)
            const localVarPath = `/api/v1/integrations/calendly/{calendlyIntegId}`
                .replace(`{${"calendlyIntegId"}}`, encodeURIComponent(String(calendlyIntegId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerraUserStatus: async (terraUserIds: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'terraUserIds' is not null or undefined
            assertParamExists('getTerraUserStatus', 'terraUserIds', terraUserIds)
            const localVarPath = `/api/v1/integrations/terra/auth/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (terraUserIds) {
                localVarQueryParameter['terraUserIds[]'] = terraUserIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoIntegrations: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTodoIntegrations', 'userId', userId)
            const localVarPath = `/api/v1/integrations/todo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {boolean} [shouldSyncTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initTodoIntegrations: async (todoType: string, token: string, userId: string, shouldSyncTasks?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoType' is not null or undefined
            assertParamExists('initTodoIntegrations', 'todoType', todoType)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('initTodoIntegrations', 'token', token)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('initTodoIntegrations', 'userId', userId)
            const localVarPath = `/api/v1/integrations/todo/init`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoType !== undefined) {
                localVarQueryParameter['todoType'] = todoType;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (shouldSyncTasks !== undefined) {
                localVarQueryParameter['shouldSyncTasks'] = shouldSyncTasks;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove todo integrations
         * @param {string} userId 
         * @param {string} todoIntegrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTodoIntegration: async (userId: string, todoIntegrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeTodoIntegration', 'userId', userId)
            // verify required parameter 'todoIntegrationId' is not null or undefined
            assertParamExists('removeTodoIntegration', 'todoIntegrationId', todoIntegrationId)
            const localVarPath = `/api/v1/integrations/todo/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (todoIntegrationId !== undefined) {
                localVarQueryParameter['todoIntegrationId'] = todoIntegrationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsApi - functional programming interface
 * @export
 */
export const IntegrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Configure Calendly integration
         * @param {string} userId 
         * @param {object} body  Partial&lt;FirestoreCalendlyIntegration&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configureCalendly(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigureCalendlyRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configureCalendly(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.configureCalendly']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Exchange code for todo integrations
         * @param {string} code 
         * @param {string} todoType TodoIntegrationType
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exchangeCode(code: string, todoType: string, redirectUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeCode(code, todoType, redirectUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.exchangeCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTerraWidget(metricId, redirectTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.generateTerraWidget']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTodoAuthUrl(todoType, callback, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.generateTodoAuthUrl']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Calendly integration info
         * @param {string} calendlyIntegId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendlyIntegInfo(calendlyIntegId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendlyIntegInfo(calendlyIntegId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.getCalendlyIntegInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetTerraUserStatusRes>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTerraUserStatus(terraUserIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.getTerraUserStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoIntegrations(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.getTodoIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {boolean} [shouldSyncTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initTodoIntegrations(todoType: string, token: string, userId: string, shouldSyncTasks?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitTodoPutRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initTodoIntegrations(todoType, token, userId, shouldSyncTasks, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.initTodoIntegrations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Remove todo integrations
         * @param {string} userId 
         * @param {string} todoIntegrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTodoIntegration(userId: string, todoIntegrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTodoIntegration(userId, todoIntegrationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationsApi.removeTodoIntegration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationsApi - factory interface
 * @export
 */
export const IntegrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Configure Calendly integration
         * @param {string} userId 
         * @param {object} body  Partial&lt;FirestoreCalendlyIntegration&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configureCalendly(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<ConfigureCalendlyRes> {
            return localVarFp.configureCalendly(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Exchange code for todo integrations
         * @param {string} code 
         * @param {string} todoType TodoIntegrationType
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exchangeCode(code: string, todoType: string, redirectUrl?: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.exchangeCode(code, todoType, redirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a Terra widget
         * @param {string} metricId Metric ID
         * @param {string} [redirectTo] Redirect to URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.generateTerraWidget(metricId, redirectTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generate auth url for todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} [callback] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.generateTodoAuthUrl(todoType, callback, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Calendly integration info
         * @param {string} calendlyIntegId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendlyIntegInfo(calendlyIntegId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getCalendlyIntegInfo(calendlyIntegId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Terra user status
         * @param {Array<string>} terraUserIds User ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<GetTerraUserStatusRes>> {
            return localVarFp.getTerraUserStatus(terraUserIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get todo integrations
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoIntegrations(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initialize todo integrations
         * @param {string} todoType TodoIntegrationType
         * @param {string} token 
         * @param {string} userId 
         * @param {boolean} [shouldSyncTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initTodoIntegrations(todoType: string, token: string, userId: string, shouldSyncTasks?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<InitTodoPutRes> {
            return localVarFp.initTodoIntegrations(todoType, token, userId, shouldSyncTasks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove todo integrations
         * @param {string} userId 
         * @param {string} todoIntegrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTodoIntegration(userId: string, todoIntegrationId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.removeTodoIntegration(userId, todoIntegrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsApi - object-oriented interface
 * @export
 * @class IntegrationsApi
 * @extends {BaseAPI}
 */
export class IntegrationsApi extends BaseAPI {
    /**
     * 
     * @summary Configure Calendly integration
     * @param {string} userId 
     * @param {object} body  Partial&lt;FirestoreCalendlyIntegration&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public configureCalendly(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).configureCalendly(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Exchange code for todo integrations
     * @param {string} code 
     * @param {string} todoType TodoIntegrationType
     * @param {string} [redirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public exchangeCode(code: string, todoType: string, redirectUrl?: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).exchangeCode(code, todoType, redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a Terra widget
     * @param {string} metricId Metric ID
     * @param {string} [redirectTo] Redirect to URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public generateTerraWidget(metricId: string, redirectTo?: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).generateTerraWidget(metricId, redirectTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generate auth url for todo integrations
     * @param {string} todoType TodoIntegrationType
     * @param {string} [callback] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public generateTodoAuthUrl(todoType: string, callback?: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).generateTodoAuthUrl(todoType, callback, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Calendly integration info
     * @param {string} calendlyIntegId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getCalendlyIntegInfo(calendlyIntegId: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getCalendlyIntegInfo(calendlyIntegId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Terra user status
     * @param {Array<string>} terraUserIds User ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getTerraUserStatus(terraUserIds: Array<string>, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getTerraUserStatus(terraUserIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get todo integrations
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public getTodoIntegrations(userId: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).getTodoIntegrations(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initialize todo integrations
     * @param {string} todoType TodoIntegrationType
     * @param {string} token 
     * @param {string} userId 
     * @param {boolean} [shouldSyncTasks] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public initTodoIntegrations(todoType: string, token: string, userId: string, shouldSyncTasks?: boolean, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).initTodoIntegrations(todoType, token, userId, shouldSyncTasks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove todo integrations
     * @param {string} userId 
     * @param {string} todoIntegrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsApi
     */
    public removeTodoIntegration(userId: string, todoIntegrationId: string, options?: RawAxiosRequestConfig) {
        return IntegrationsApiFp(this.configuration).removeTodoIntegration(userId, todoIntegrationId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTerraWebhook: async (metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metrics/webhook/terra`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(metricsWebhookTerraPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseChronotype: async (userId: string, metricId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('diagnoseChronotype', 'userId', userId)
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('diagnoseChronotype', 'metricId', metricId)
            const localVarPath = `/api/v1/metrics/sleep/diagnoseChronotype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (metricId !== undefined) {
                localVarQueryParameter['metricId'] = metricId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get exercise details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseDetails: async (metricId: string, dates: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getExerciseDetails', 'metricId', metricId)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getExerciseDetails', 'dates', dates)
            const localVarPath = `/api/v1/metrics/exercise/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dates) {
                localVarQueryParameter['dates[]'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get meal details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealDetails: async (metricId: string, dates: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getMealDetails', 'metricId', metricId)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getMealDetails', 'dates', dates)
            const localVarPath = `/api/v1/metrics/meal/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dates) {
                localVarQueryParameter['dates[]'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get phase details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhases: async (metricId: string, dates: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getPhases', 'metricId', metricId)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getPhases', 'dates', dates)
            const localVarPath = `/api/v1/metrics/phase/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dates) {
                localVarQueryParameter['dates[]'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDetails: async (metricId: string, userId: string, dates: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSleepDetails', 'userId', userId)
            // verify required parameter 'dates' is not null or undefined
            assertParamExists('getSleepDetails', 'dates', dates)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (dates) {
                localVarQueryParameter['dates[]'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weekly sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklySleepTrend: async (metricId: string, userId: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('getWeeklySleepTrend', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getWeeklySleepTrend', 'userId', userId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWeeklySleepTrend', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWeeklySleepTrend', 'endDate', endDate)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}/trend/weekly`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hide exercise activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideExercise: async (metricId: string, date: string, activityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('hideExercise', 'metricId', metricId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('hideExercise', 'date', date)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('hideExercise', 'activityId', activityId)
            const localVarPath = `/api/v1/metrics/exercise/{metricId}/hide`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (activityId !== undefined) {
                localVarQueryParameter['activityId'] = activityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hide meal activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} mealActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideMeal: async (metricId: string, date: string, mealActivityId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('hideMeal', 'metricId', metricId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('hideMeal', 'date', date)
            // verify required parameter 'mealActivityId' is not null or undefined
            assertParamExists('hideMeal', 'mealActivityId', mealActivityId)
            const localVarPath = `/api/v1/metrics/meal/{metricId}/hide`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (mealActivityId !== undefined) {
                localVarQueryParameter['mealActivityId'] = mealActivityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHeartRate: async (userId: string, date: string, device: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('saveHeartRate', 'userId', userId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('saveHeartRate', 'date', date)
            // verify required parameter 'device' is not null or undefined
            assertParamExists('saveHeartRate', 'device', device)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saveHeartRate', 'body', body)
            const localVarPath = `/api/v1/metrics/heartRate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (device !== undefined) {
                localVarQueryParameter['device'] = device;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync exercise
         * @param {string} metricId 
         * @param {SyncExerciseBody} syncExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncExercise: async (metricId: string, syncExerciseBody: SyncExerciseBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('syncExercise', 'metricId', metricId)
            // verify required parameter 'syncExerciseBody' is not null or undefined
            assertParamExists('syncExercise', 'syncExerciseBody', syncExerciseBody)
            const localVarPath = `/api/v1/metrics/exercise/{metricId}/sync`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncExerciseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync meal
         * @param {string} metricId 
         * @param {SyncMealBody} syncMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMeal: async (metricId: string, syncMealBody: SyncMealBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('syncMeal', 'metricId', metricId)
            // verify required parameter 'syncMealBody' is not null or undefined
            assertParamExists('syncMeal', 'syncMealBody', syncMealBody)
            const localVarPath = `/api/v1/metrics/meal/{metricId}/sync`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncMealBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSleepDetails: async (metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('syncSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncSleepDetails', 'userId', userId)
            // verify required parameter 'terraUserIds' is not null or undefined
            assertParamExists('syncSleepDetails', 'terraUserIds', terraUserIds)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('syncSleepDetails', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('syncSleepDetails', 'endDate', endDate)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}/sync`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (terraUserIds) {
                localVarQueryParameter['terraUserIds[]'] = terraUserIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update exercise
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateExerciseBody} updateExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExercise: async (metricId: string, userId: string, updateExerciseBody: UpdateExerciseBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateExercise', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateExercise', 'userId', userId)
            // verify required parameter 'updateExerciseBody' is not null or undefined
            assertParamExists('updateExercise', 'updateExerciseBody', updateExerciseBody)
            const localVarPath = `/api/v1/metrics/exercise/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExerciseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update meal
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateMealBody} updateMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeal: async (metricId: string, userId: string, updateMealBody: UpdateMealBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateMeal', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateMeal', 'userId', userId)
            // verify required parameter 'updateMealBody' is not null or undefined
            assertParamExists('updateMeal', 'updateMealBody', updateMealBody)
            const localVarPath = `/api/v1/metrics/meal/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMealBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails: async (metricId: string, userId: string, requestBody: Array<object>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateSleepDetails', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSleepDetails', 'userId', userId)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('updateSleepDetails', 'requestBody', requestBody)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails_1: async (metricId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'metricId' is not null or undefined
            assertParamExists('updateSleepDetails_1', 'metricId', metricId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSleepDetails_1', 'userId', userId)
            const localVarPath = `/api/v1/metrics/sleep/{metricId}/`
                .replace(`{${"metricId"}}`, encodeURIComponent(String(metricId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consumeTerraWebhook(metricsWebhookTerraPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.consumeTerraWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diagnoseChronotype(userId, metricId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.diagnoseChronotype']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get exercise details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExerciseDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExerciseDetails(metricId, dates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getExerciseDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get meal details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealDetails(metricId, dates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getMealDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get phase details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhases(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhases(metricId, dates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getPhases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSleepDetails(metricId, userId, dates, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get weekly sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklySleepTrend(metricId: string, userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SleepDataTrendWeeklyRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklySleepTrend(metricId, userId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.getWeeklySleepTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hide exercise activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideExercise(metricId: string, date: string, activityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideExercise(metricId, date, activityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.hideExercise']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Hide meal activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} mealActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hideMeal(metricId: string, date: string, mealActivityId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hideMeal(metricId, date, mealActivityId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.hideMeal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveHeartRate(userId, date, device, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.saveHeartRate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync exercise
         * @param {string} metricId 
         * @param {SyncExerciseBody} syncExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncExercise(metricId: string, syncExerciseBody: SyncExerciseBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncSuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncExercise(metricId, syncExerciseBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.syncExercise']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync meal
         * @param {string} metricId 
         * @param {SyncMealBody} syncMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncMeal(metricId: string, syncMealBody: SyncMealBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncSuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncMeal(metricId, syncMealBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.syncMeal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncSuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.syncSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update exercise
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateExerciseBody} updateExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExercise(metricId: string, userId: string, updateExerciseBody: UpdateExerciseBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExercise(metricId, userId, updateExerciseBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.updateExercise']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update meal
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateMealBody} updateMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMeal(metricId: string, userId: string, updateMealBody: UpdateMealBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMeal(metricId, userId, updateMealBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.updateMeal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSleepDetails(metricId, userId, requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.updateSleepDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSleepDetails_1(metricId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSleepDetails_1(metricId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MetricsApi.updateSleepDetails_1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @summary Consume Terra webhook
         * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.consumeTerraWebhook(metricsWebhookTerraPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Diagnose chronotype
         * @param {string} userId 
         * @param {string} metricId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.diagnoseChronotype(userId, metricId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get exercise details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExerciseDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getExerciseDetails(metricId, dates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get meal details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getMealDetails(metricId, dates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get phase details
         * @param {string} metricId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhases(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getPhases(metricId, dates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} dates 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getSleepDetails(metricId, userId, dates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weekly sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklySleepTrend(metricId: string, userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<SleepDataTrendWeeklyRes> {
            return localVarFp.getWeeklySleepTrend(metricId, userId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hide exercise activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} activityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideExercise(metricId: string, date: string, activityId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.hideExercise(metricId, date, activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hide meal activity
         * @param {string} metricId 
         * @param {string} date 
         * @param {string} mealActivityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hideMeal(metricId: string, date: string, mealActivityId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.hideMeal(metricId, date, mealActivityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save heart rate data
         * @param {string} userId 
         * @param {string} date 
         * @param {string} device 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.saveHeartRate(userId, date, device, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync exercise
         * @param {string} metricId 
         * @param {SyncExerciseBody} syncExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncExercise(metricId: string, syncExerciseBody: SyncExerciseBody, options?: RawAxiosRequestConfig): AxiosPromise<SyncSuccessRes> {
            return localVarFp.syncExercise(metricId, syncExerciseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync meal
         * @param {string} metricId 
         * @param {SyncMealBody} syncMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncMeal(metricId: string, syncMealBody: SyncMealBody, options?: RawAxiosRequestConfig): AxiosPromise<SyncSuccessRes> {
            return localVarFp.syncMeal(metricId, syncMealBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<string>} terraUserIds 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<SyncSuccessRes> {
            return localVarFp.syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update exercise
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateExerciseBody} updateExerciseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExercise(metricId: string, userId: string, updateExerciseBody: UpdateExerciseBody, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateExercise(metricId, userId, updateExerciseBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update meal
         * @param {string} metricId 
         * @param {string} userId 
         * @param {UpdateMealBody} updateMealBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMeal(metricId: string, userId: string, updateMealBody: UpdateMealBody, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateMeal(metricId, userId, updateMealBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {Array<object>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSleepDetails(metricId, userId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sleep details
         * @param {string} metricId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSleepDetails_1(metricId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSleepDetails_1(metricId, userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @summary Consume Terra webhook
     * @param {MetricsWebhookTerraPutReq} [metricsWebhookTerraPutReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public consumeTerraWebhook(metricsWebhookTerraPutReq?: MetricsWebhookTerraPutReq, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).consumeTerraWebhook(metricsWebhookTerraPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Diagnose chronotype
     * @param {string} userId 
     * @param {string} metricId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public diagnoseChronotype(userId: string, metricId: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).diagnoseChronotype(userId, metricId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get exercise details
     * @param {string} metricId 
     * @param {Array<string>} dates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getExerciseDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getExerciseDetails(metricId, dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get meal details
     * @param {string} metricId 
     * @param {Array<string>} dates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMealDetails(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMealDetails(metricId, dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get phase details
     * @param {string} metricId 
     * @param {Array<string>} dates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getPhases(metricId: string, dates: Array<string>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getPhases(metricId, dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<string>} dates 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getSleepDetails(metricId: string, userId: string, dates: Array<string>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getSleepDetails(metricId, userId, dates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weekly sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getWeeklySleepTrend(metricId: string, userId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getWeeklySleepTrend(metricId, userId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hide exercise activity
     * @param {string} metricId 
     * @param {string} date 
     * @param {string} activityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public hideExercise(metricId: string, date: string, activityId: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).hideExercise(metricId, date, activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hide meal activity
     * @param {string} metricId 
     * @param {string} date 
     * @param {string} mealActivityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public hideMeal(metricId: string, date: string, mealActivityId: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).hideMeal(metricId, date, mealActivityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save heart rate data
     * @param {string} userId 
     * @param {string} date 
     * @param {string} device 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public saveHeartRate(userId: string, date: string, device: string, body: object, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).saveHeartRate(userId, date, device, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync exercise
     * @param {string} metricId 
     * @param {SyncExerciseBody} syncExerciseBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public syncExercise(metricId: string, syncExerciseBody: SyncExerciseBody, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).syncExercise(metricId, syncExerciseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync meal
     * @param {string} metricId 
     * @param {SyncMealBody} syncMealBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public syncMeal(metricId: string, syncMealBody: SyncMealBody, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).syncMeal(metricId, syncMealBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<string>} terraUserIds 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public syncSleepDetails(metricId: string, userId: string, terraUserIds: Array<string>, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update exercise
     * @param {string} metricId 
     * @param {string} userId 
     * @param {UpdateExerciseBody} updateExerciseBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateExercise(metricId: string, userId: string, updateExerciseBody: UpdateExerciseBody, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateExercise(metricId, userId, updateExerciseBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update meal
     * @param {string} metricId 
     * @param {string} userId 
     * @param {UpdateMealBody} updateMealBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateMeal(metricId: string, userId: string, updateMealBody: UpdateMealBody, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateMeal(metricId, userId, updateMealBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {Array<object>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateSleepDetails(metricId: string, userId: string, requestBody: Array<object>, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateSleepDetails(metricId, userId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sleep details
     * @param {string} metricId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public updateSleepDetails_1(metricId: string, userId: string, options?: RawAxiosRequestConfig) {
        return MetricsApiFp(this.configuration).updateSleepDetails_1(metricId, userId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecommendedTask: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteRecommendedTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('deleteRecommendedTask', 'body', body)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedTasks: async (userId: string, origin?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getRecommendedTasks', 'userId', userId)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (origin !== undefined) {
                localVarQueryParameter['origin'] = origin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRecommendedTasks: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resetRecommendedTasks', 'userId', userId)
            const localVarPath = `/api/v1/recommendations/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecommendedTasks: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateRecommendedTasks', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateRecommendedTasks', 'body', body)
            const localVarPath = `/api/v1/recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RecommendationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecommendedTask(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.deleteRecommendedTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendedTasks(userId, origin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.getRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetRecommendedTasks(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.resetRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRecommendedTasks(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RecommendationsApi.updateRecommendedTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RecommendationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteRecommendedTask(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recommended tasks
         * @param {string} userId 
         * @param {string} [origin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getRecommendedTasks(userId, origin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset recommended tasks
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.resetRecommendedTasks(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update recommended tasks
         * @param {string} userId 
         * @param {object} body RecommendedTask
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateRecommendedTasks(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI {
    /**
     * 
     * @summary Delete recommended tasks
     * @param {string} userId 
     * @param {object} body RecommendedTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public deleteRecommendedTask(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).deleteRecommendedTask(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recommended tasks
     * @param {string} userId 
     * @param {string} [origin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public getRecommendedTasks(userId: string, origin?: string, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).getRecommendedTasks(userId, origin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset recommended tasks
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public resetRecommendedTasks(userId: string, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).resetRecommendedTasks(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update recommended tasks
     * @param {string} userId 
     * @param {object} body RecommendedTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public updateRecommendedTasks(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration).updateRecommendedTasks(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchedulerApi - axios parameter creator
 * @export
 */
export const SchedulerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate AI Planner Time Frame
         * @param {string} dataTrendId 
         * @param {CalcAIPlannerTimeFrameBody} calcAIPlannerTimeFrameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcAIPlannerTimeFrame: async (dataTrendId: string, calcAIPlannerTimeFrameBody: CalcAIPlannerTimeFrameBody, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('calcAIPlannerTimeFrame', 'dataTrendId', dataTrendId)
            // verify required parameter 'calcAIPlannerTimeFrameBody' is not null or undefined
            assertParamExists('calcAIPlannerTimeFrame', 'calcAIPlannerTimeFrameBody', calcAIPlannerTimeFrameBody)
            const localVarPath = `/api/v1/scheduler/aiScheduler/calcTimeFrame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataTrendId !== undefined) {
                localVarQueryParameter['dataTrendId'] = dataTrendId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calcAIPlannerTimeFrameBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get energy-boost preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyBoostPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getEnergyBoostPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/scheduler/preference/{preferenceId}/energyBoost`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get scheduler preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulerPreference: async (preferenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('getSchedulerPreference', 'preferenceId', preferenceId)
            const localVarPath = `/api/v1/scheduler/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run AI Task Scheduler (Task)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAITaskScheduler: async (userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('runAITaskScheduler', 'userId', userId)
            // verify required parameter 'schedulerAITimeBoxPlannerPutReq' is not null or undefined
            assertParamExists('runAITaskScheduler', 'schedulerAITimeBoxPlannerPutReq', schedulerAITimeBoxPlannerPutReq)
            const localVarPath = `/api/v1/scheduler/aiScheduler/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedulerAITimeBoxPlannerPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run AI TimeBox Planner
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        runAITimeBoxPlanner: async (userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('runAITimeBoxPlanner', 'userId', userId)
            // verify required parameter 'schedulerAITimeBoxPlannerPutReq' is not null or undefined
            assertParamExists('runAITimeBoxPlanner', 'schedulerAITimeBoxPlannerPutReq', schedulerAITimeBoxPlannerPutReq)
            const localVarPath = `/api/v1/scheduler/aiScheduler/timeboxing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedulerAITimeBoxPlannerPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run AI TimeBox Planner (v2 formatted response)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAITimeBoxPlannerV2FormattedRes: async (userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('runAITimeBoxPlannerV2FormattedRes', 'userId', userId)
            // verify required parameter 'schedulerAITimeBoxPlannerPutReq' is not null or undefined
            assertParamExists('runAITimeBoxPlannerV2FormattedRes', 'schedulerAITimeBoxPlannerPutReq', schedulerAITimeBoxPlannerPutReq)
            const localVarPath = `/api/v1/scheduler/aiScheduler/timeboxing/v2FormattedRes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedulerAITimeBoxPlannerPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleEnergyBoost: async (userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('scheduleEnergyBoost', 'userId', userId)
            // verify required parameter 'schedulerEnergyBoostPutReq' is not null or undefined
            assertParamExists('scheduleEnergyBoost', 'schedulerEnergyBoostPutReq', schedulerEnergyBoostPutReq)
            const localVarPath = `/api/v1/scheduler/energyBoost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedulerEnergyBoostPutReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;EnergyBoostPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnergyBoostPreference: async (preferenceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updateEnergyBoostPreference', 'preferenceId', preferenceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateEnergyBoostPreference', 'body', body)
            const localVarPath = `/api/v1/scheduler/preference/{preferenceId}/energyBoost`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;SchedulerPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedulerPreference: async (preferenceId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preferenceId' is not null or undefined
            assertParamExists('updateSchedulerPreference', 'preferenceId', preferenceId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateSchedulerPreference', 'body', body)
            const localVarPath = `/api/v1/scheduler/preference/{preferenceId}`
                .replace(`{${"preferenceId"}}`, encodeURIComponent(String(preferenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchedulerApi - functional programming interface
 * @export
 */
export const SchedulerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchedulerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate AI Planner Time Frame
         * @param {string} dataTrendId 
         * @param {CalcAIPlannerTimeFrameBody} calcAIPlannerTimeFrameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcAIPlannerTimeFrame(dataTrendId: string, calcAIPlannerTimeFrameBody: CalcAIPlannerTimeFrameBody, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalcTimeFrame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcAIPlannerTimeFrame(dataTrendId, calcAIPlannerTimeFrameBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.calcAIPlannerTimeFrame']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get energy-boost preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnergyBoostPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnergyBoostPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.getEnergyBoostPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get scheduler preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulerPreference(preferenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchedulerPreference(preferenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.getSchedulerPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run AI Task Scheduler (Task)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runAITaskScheduler(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchedulerAITimeBoxPlannerPutFormattedRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runAITaskScheduler(userId, schedulerAITimeBoxPlannerPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.runAITaskScheduler']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run AI TimeBox Planner
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async runAITimeBoxPlanner(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runAITimeBoxPlanner(userId, schedulerAITimeBoxPlannerPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.runAITimeBoxPlanner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run AI TimeBox Planner (v2 formatted response)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runAITimeBoxPlannerV2FormattedRes(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchedulerAITimeBoxPlannerPutFormattedRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runAITimeBoxPlannerV2FormattedRes(userId, schedulerAITimeBoxPlannerPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.runAITimeBoxPlannerV2FormattedRes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.scheduleEnergyBoost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;EnergyBoostPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnergyBoostPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnergyBoostPreference(preferenceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.updateEnergyBoostPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;SchedulerPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSchedulerPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSchedulerPreference(preferenceId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchedulerApi.updateSchedulerPreference']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchedulerApi - factory interface
 * @export
 */
export const SchedulerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchedulerApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate AI Planner Time Frame
         * @param {string} dataTrendId 
         * @param {CalcAIPlannerTimeFrameBody} calcAIPlannerTimeFrameBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcAIPlannerTimeFrame(dataTrendId: string, calcAIPlannerTimeFrameBody: CalcAIPlannerTimeFrameBody, options?: RawAxiosRequestConfig): AxiosPromise<CalcTimeFrame> {
            return localVarFp.calcAIPlannerTimeFrame(dataTrendId, calcAIPlannerTimeFrameBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get energy-boost preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnergyBoostPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getEnergyBoostPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get scheduler preference
         * @param {string} preferenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulerPreference(preferenceId: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getSchedulerPreference(preferenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run AI Task Scheduler (Task)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAITaskScheduler(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): AxiosPromise<SchedulerAITimeBoxPlannerPutFormattedRes> {
            return localVarFp.runAITaskScheduler(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run AI TimeBox Planner
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        runAITimeBoxPlanner(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.runAITimeBoxPlanner(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run AI TimeBox Planner (v2 formatted response)
         * @param {string} userId 
         * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runAITimeBoxPlannerV2FormattedRes(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig): AxiosPromise<SchedulerAITimeBoxPlannerPutFormattedRes> {
            return localVarFp.runAITimeBoxPlannerV2FormattedRes(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run Energy boost
         * @param {string} userId 
         * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;EnergyBoostPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnergyBoostPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateEnergyBoostPreference(preferenceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferences for scheduler
         * @param {string} preferenceId 
         * @param {object} body Partial&lt;SchedulerPreference&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedulerPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.updateSchedulerPreference(preferenceId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchedulerApi - object-oriented interface
 * @export
 * @class SchedulerApi
 * @extends {BaseAPI}
 */
export class SchedulerApi extends BaseAPI {
    /**
     * 
     * @summary Calculate AI Planner Time Frame
     * @param {string} dataTrendId 
     * @param {CalcAIPlannerTimeFrameBody} calcAIPlannerTimeFrameBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public calcAIPlannerTimeFrame(dataTrendId: string, calcAIPlannerTimeFrameBody: CalcAIPlannerTimeFrameBody, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).calcAIPlannerTimeFrame(dataTrendId, calcAIPlannerTimeFrameBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get energy-boost preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getEnergyBoostPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).getEnergyBoostPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get scheduler preference
     * @param {string} preferenceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public getSchedulerPreference(preferenceId: string, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).getSchedulerPreference(preferenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run AI Task Scheduler (Task)
     * @param {string} userId 
     * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public runAITaskScheduler(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).runAITaskScheduler(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run AI TimeBox Planner
     * @param {string} userId 
     * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public runAITimeBoxPlanner(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).runAITimeBoxPlanner(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run AI TimeBox Planner (v2 formatted response)
     * @param {string} userId 
     * @param {SchedulerAITimeBoxPlannerPutReq} schedulerAITimeBoxPlannerPutReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public runAITimeBoxPlannerV2FormattedRes(userId: string, schedulerAITimeBoxPlannerPutReq: SchedulerAITimeBoxPlannerPutReq, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).runAITimeBoxPlannerV2FormattedRes(userId, schedulerAITimeBoxPlannerPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run Energy boost
     * @param {string} userId 
     * @param {SchedulerEnergyBoostPutReq} schedulerEnergyBoostPutReq 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public scheduleEnergyBoost(userId: string, schedulerEnergyBoostPutReq: SchedulerEnergyBoostPutReq, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).scheduleEnergyBoost(userId, schedulerEnergyBoostPutReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferences for scheduler
     * @param {string} preferenceId 
     * @param {object} body Partial&lt;EnergyBoostPreference&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public updateEnergyBoostPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).updateEnergyBoostPreference(preferenceId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferences for scheduler
     * @param {string} preferenceId 
     * @param {object} body Partial&lt;SchedulerPreference&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchedulerApi
     */
    public updateSchedulerPreference(preferenceId: string, body: object, options?: RawAxiosRequestConfig) {
        return SchedulerApiFp(this.configuration).updateSchedulerPreference(preferenceId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoProject: async (userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTodoProject', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addTodoProject', 'body', body)
            const localVarPath = `/api/v1/tasks/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoTask: async (boardId: string, userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('addTodoTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addTodoTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('addTodoTask', 'body', body)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodoTask: async (taskId: string, boardId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('deleteTodoTask', 'taskId', taskId)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('deleteTodoTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTodoTask', 'userId', userId)
            const localVarPath = `/api/v1/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all labels
         * @param {string} taskLabelsGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTaskLabels: async (taskLabelsGroupId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskLabelsGroupId' is not null or undefined
            assertParamExists('getAllTaskLabels', 'taskLabelsGroupId', taskLabelsGroupId)
            const localVarPath = `/api/v1/tasks/labels/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskLabelsGroupId !== undefined) {
                localVarQueryParameter['taskLabelsGroupId'] = taskLabelsGroupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get daily tasks trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyTasksTrend: async (dataTrendId: string, date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getDailyTasksTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getDailyTasksTrend', 'date', date)
            const localVarPath = `/api/v1/tasks/trend/{dataTrendId}/daily`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {string} [orderBy] dueDate
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {boolean} [hideNoDueDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueTodoTasks: async (todoTasksBoardIds: Array<string>, dueDateTill: string, orderBy?: string, labelNames?: string, completed?: boolean, hideNoDueDate?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoTasksBoardIds' is not null or undefined
            assertParamExists('getOverdueTodoTasks', 'todoTasksBoardIds', todoTasksBoardIds)
            // verify required parameter 'dueDateTill' is not null or undefined
            assertParamExists('getOverdueTodoTasks', 'dueDateTill', dueDateTill)
            const localVarPath = `/api/v1/tasks/overdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoTasksBoardIds) {
                localVarQueryParameter['todoTasksBoardIds[]'] = todoTasksBoardIds;
            }

            if (dueDateTill !== undefined) {
                localVarQueryParameter['dueDateTill'] = dueDateTill;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (labelNames !== undefined) {
                localVarQueryParameter['labelNames'] = labelNames;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (hideNoDueDate !== undefined) {
                localVarQueryParameter['hideNoDueDate'] = hideNoDueDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoProjects: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTodoProjects', 'userId', userId)
            const localVarPath = `/api/v1/tasks/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoTasks: async (todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, labelNames?: string, completed?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'todoTasksBoardIds' is not null or undefined
            assertParamExists('getTodoTasks', 'todoTasksBoardIds', todoTasksBoardIds)
            const localVarPath = `/api/v1/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (todoTasksBoardIds) {
                localVarQueryParameter['todoTasksBoardIds[]'] = todoTasksBoardIds;
            }

            if (dueDate !== undefined) {
                localVarQueryParameter['dueDate'] = dueDate;
            }

            if (onlyMyTask !== undefined) {
                localVarQueryParameter['onlyMyTask'] = onlyMyTask;
            }

            if (labelNames !== undefined) {
                localVarQueryParameter['labelNames'] = labelNames;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get weekly tasks trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyTasksTrend: async (dataTrendId: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataTrendId' is not null or undefined
            assertParamExists('getWeeklyTasksTrend', 'dataTrendId', dataTrendId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWeeklyTasksTrend', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWeeklyTasksTrend', 'endDate', endDate)
            const localVarPath = `/api/v1/tasks/trend/{dataTrendId}/weekly`
                .replace(`{${"dataTrendId"}}`, encodeURIComponent(String(dataTrendId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set a project visibility
         * @param {string} boardId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoProjectVisibility: async (boardId: string, closed: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('setTodoProjectVisibility', 'boardId', boardId)
            // verify required parameter 'closed' is not null or undefined
            assertParamExists('setTodoProjectVisibility', 'closed', closed)
            const localVarPath = `/api/v1/tasks/projects/visibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (closed !== undefined) {
                localVarQueryParameter['closed'] = closed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoProjects: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncTodoProjects', 'userId', userId)
            const localVarPath = `/api/v1/tasks/projects/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasks: async (userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, fullSync?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncTodoTasks', 'userId', userId)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('syncTodoTasks', 'timezone', timezone)
            // verify required parameter 'targetTodoIntegrationIds' is not null or undefined
            assertParamExists('syncTodoTasks', 'targetTodoIntegrationIds', targetTodoIntegrationIds)
            const localVarPath = `/api/v1/tasks/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (fullSync !== undefined) {
                localVarQueryParameter['fullSync'] = fullSync;
            }

            if (targetTodoIntegrationIds) {
                localVarQueryParameter['targetTodoIntegrationIds[]'] = targetTodoIntegrationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync todo tasks by projects
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} projectIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasksByProjects: async (userId: string, timezone: string, projectIds: Array<string>, fullSync?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('syncTodoTasksByProjects', 'userId', userId)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('syncTodoTasksByProjects', 'timezone', timezone)
            // verify required parameter 'projectIds' is not null or undefined
            assertParamExists('syncTodoTasksByProjects', 'projectIds', projectIds)
            const localVarPath = `/api/v1/tasks/syncByProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (timezone !== undefined) {
                localVarQueryParameter['timezone'] = timezone;
            }

            if (fullSync !== undefined) {
                localVarQueryParameter['fullSync'] = fullSync;
            }

            if (projectIds) {
                localVarQueryParameter['projectIds[]'] = projectIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a complete status of a task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {boolean} completed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompleteStatusOfTask: async (taskId: string, boardId: string, userId: string, completed: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateCompleteStatusOfTask', 'taskId', taskId)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('updateCompleteStatusOfTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateCompleteStatusOfTask', 'userId', userId)
            // verify required parameter 'completed' is not null or undefined
            assertParamExists('updateCompleteStatusOfTask', 'completed', completed)
            const localVarPath = `/api/v1/tasks/{taskId}/complete`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a label
         * @param {string} taskLabelGroupId 
         * @param {string} taskLabelId 
         * @param {object} body Partial&lt;TaskLabel&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskLabel: async (taskLabelGroupId: string, taskLabelId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskLabelGroupId' is not null or undefined
            assertParamExists('updateTaskLabel', 'taskLabelGroupId', taskLabelGroupId)
            // verify required parameter 'taskLabelId' is not null or undefined
            assertParamExists('updateTaskLabel', 'taskLabelId', taskLabelId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTaskLabel', 'body', body)
            const localVarPath = `/api/v1/tasks/labels/{taskLabelId}`
                .replace(`{${"taskLabelId"}}`, encodeURIComponent(String(taskLabelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (taskLabelGroupId !== undefined) {
                localVarQueryParameter['taskLabelGroupId'] = taskLabelGroupId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodoTask: async (taskId: string, boardId: string, userId: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('updateTodoTask', 'taskId', taskId)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('updateTodoTask', 'boardId', boardId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateTodoTask', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateTodoTask', 'body', body)
            const localVarPath = `/api/v1/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (boardId !== undefined) {
                localVarQueryParameter['boardId'] = boardId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskProjectIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTodoProject(userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.addTodoProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTodoTask(boardId, userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.addTodoTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTodoTask(taskId: string, boardId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTodoTask(taskId, boardId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.deleteTodoTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all labels
         * @param {string} taskLabelsGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTaskLabels(taskLabelsGroupId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTaskLabels(taskLabelsGroupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getAllTaskLabels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get daily tasks trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDailyTasksTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDailyTasksTrend(dataTrendId, date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getDailyTasksTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {string} [orderBy] dueDate
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {boolean} [hideNoDueDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, orderBy?: string, labelNames?: string, completed?: boolean, hideNoDueDate?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, orderBy, labelNames, completed, hideNoDueDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getOverdueTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoProjects(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoProjects(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getTodoProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, labelNames?: string, completed?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, labelNames, completed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get weekly tasks trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeeklyTasksTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskDataTrendWeeklyRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeeklyTasksTrend(dataTrendId, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.getWeeklyTasksTrend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Set a project visibility
         * @param {string} boardId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTodoProjectVisibility(boardId: string, closed: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTodoProjectVisibility(boardId, closed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.setTodoProjectVisibility']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTodoProjects(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTodoProjects(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.syncTodoProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTodoTasks(userId, timezone, targetTodoIntegrationIds, fullSync, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.syncTodoTasks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Sync todo tasks by projects
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} projectIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTodoTasksByProjects(userId: string, timezone: string, projectIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTodoTasksByProjects(userId, timezone, projectIds, fullSync, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.syncTodoTasksByProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a complete status of a task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {boolean} completed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompleteStatusOfTask(taskId: string, boardId: string, userId: string, completed: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompleteStatusOfTask(taskId, boardId, userId, completed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateCompleteStatusOfTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a label
         * @param {string} taskLabelGroupId 
         * @param {string} taskLabelId 
         * @param {object} body Partial&lt;TaskLabel&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskLabel(taskLabelGroupId: string, taskLabelId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskLabel(taskLabelGroupId, taskLabelId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateTaskLabel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskIdRes>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTodoTask(taskId, boardId, userId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TasksApi.updateTodoTask']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new project
         * @param {string} userId 
         * @param {object} body AddTodotaskBoardProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskProjectIdRes> {
            return localVarFp.addTodoProject(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a todo task
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body AddTaskItemProps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.addTodoTask(boardId, userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTodoTask(taskId: string, boardId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.deleteTodoTask(taskId, boardId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all labels
         * @param {string} taskLabelsGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTaskLabels(taskLabelsGroupId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getAllTaskLabels(taskLabelsGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get daily tasks trend
         * @param {string} dataTrendId 
         * @param {string} date YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDailyTasksTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getDailyTasksTrend(dataTrendId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
         * @summary Get all overdue todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} dueDateTill YYYY-MM-DD
         * @param {string} [orderBy] dueDate
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {boolean} [hideNoDueDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, orderBy?: string, labelNames?: string, completed?: boolean, hideNoDueDate?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, orderBy, labelNames, completed, hideNoDueDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoProjects(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoProjects(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all todo tasks
         * @param {Array<string>} todoTasksBoardIds 
         * @param {string} [dueDate] 
         * @param {boolean} [onlyMyTask] 
         * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
         * @param {boolean} [completed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, labelNames?: string, completed?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, labelNames, completed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get weekly tasks trend
         * @param {string} dataTrendId 
         * @param {string} startDate YYYY-MM-DD
         * @param {string} endDate YYYY-MM-DD
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeeklyTasksTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): AxiosPromise<TaskDataTrendWeeklyRes> {
            return localVarFp.getWeeklyTasksTrend(dataTrendId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set a project visibility
         * @param {string} boardId 
         * @param {boolean} closed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoProjectVisibility(boardId: string, closed: boolean, options?: RawAxiosRequestConfig): AxiosPromise<SuccessRes> {
            return localVarFp.setTodoProjectVisibility(boardId, closed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync all projects
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoProjects(userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.syncTodoProjects(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync todo tasks
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} targetTodoIntegrationIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.syncTodoTasks(userId, timezone, targetTodoIntegrationIds, fullSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync todo tasks by projects
         * @param {string} userId 
         * @param {string} timezone 
         * @param {Array<string>} projectIds 
         * @param {boolean} [fullSync] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTodoTasksByProjects(userId: string, timezone: string, projectIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<object>> {
            return localVarFp.syncTodoTasksByProjects(userId, timezone, projectIds, fullSync, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a complete status of a task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {boolean} completed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompleteStatusOfTask(taskId: string, boardId: string, userId: string, completed: boolean, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.updateCompleteStatusOfTask(taskId, boardId, userId, completed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a label
         * @param {string} taskLabelGroupId 
         * @param {string} taskLabelId 
         * @param {object} body Partial&lt;TaskLabel&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskLabel(taskLabelGroupId: string, taskLabelId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.updateTaskLabel(taskLabelGroupId, taskLabelId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a todo task
         * @param {string} taskId 
         * @param {string} boardId 
         * @param {string} userId 
         * @param {object} body Partial&lt;FirestoreTaskItem&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig): AxiosPromise<TaskIdRes> {
            return localVarFp.updateTodoTask(taskId, boardId, userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @summary Add a new project
     * @param {string} userId 
     * @param {object} body AddTodotaskBoardProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTodoProject(userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTodoProject(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a todo task
     * @param {string} boardId 
     * @param {string} userId 
     * @param {object} body AddTaskItemProps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public addTodoTask(boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).addTodoTask(boardId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a todo task
     * @param {string} taskId 
     * @param {string} boardId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTodoTask(taskId: string, boardId: string, userId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTodoTask(taskId, boardId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all labels
     * @param {string} taskLabelsGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getAllTaskLabels(taskLabelsGroupId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getAllTaskLabels(taskLabelsGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get daily tasks trend
     * @param {string} dataTrendId 
     * @param {string} date YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getDailyTasksTrend(dataTrendId: string, date: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getDailyTasksTrend(dataTrendId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch overdue tasks by the selected date. So, if you pass 2024-09-30, it will return overdue(not completed) tasks until 2024-09-30.
     * @summary Get all overdue todo tasks
     * @param {Array<string>} todoTasksBoardIds 
     * @param {string} dueDateTill YYYY-MM-DD
     * @param {string} [orderBy] dueDate
     * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
     * @param {boolean} [completed] 
     * @param {boolean} [hideNoDueDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getOverdueTodoTasks(todoTasksBoardIds: Array<string>, dueDateTill: string, orderBy?: string, labelNames?: string, completed?: boolean, hideNoDueDate?: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getOverdueTodoTasks(todoTasksBoardIds, dueDateTill, orderBy, labelNames, completed, hideNoDueDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all projects
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTodoProjects(userId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTodoProjects(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all todo tasks
     * @param {Array<string>} todoTasksBoardIds 
     * @param {string} [dueDate] 
     * @param {boolean} [onlyMyTask] 
     * @param {string} [labelNames] Either \&#39;lifestack_all\&#39; or comma-separated label names (e.g., \&quot;label1,label2\&quot;)
     * @param {boolean} [completed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getTodoTasks(todoTasksBoardIds: Array<string>, dueDate?: string, onlyMyTask?: boolean, labelNames?: string, completed?: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getTodoTasks(todoTasksBoardIds, dueDate, onlyMyTask, labelNames, completed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get weekly tasks trend
     * @param {string} dataTrendId 
     * @param {string} startDate YYYY-MM-DD
     * @param {string} endDate YYYY-MM-DD
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public getWeeklyTasksTrend(dataTrendId: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).getWeeklyTasksTrend(dataTrendId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set a project visibility
     * @param {string} boardId 
     * @param {boolean} closed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public setTodoProjectVisibility(boardId: string, closed: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).setTodoProjectVisibility(boardId, closed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync all projects
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public syncTodoProjects(userId: string, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).syncTodoProjects(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync todo tasks
     * @param {string} userId 
     * @param {string} timezone 
     * @param {Array<string>} targetTodoIntegrationIds 
     * @param {boolean} [fullSync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public syncTodoTasks(userId: string, timezone: string, targetTodoIntegrationIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).syncTodoTasks(userId, timezone, targetTodoIntegrationIds, fullSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync todo tasks by projects
     * @param {string} userId 
     * @param {string} timezone 
     * @param {Array<string>} projectIds 
     * @param {boolean} [fullSync] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public syncTodoTasksByProjects(userId: string, timezone: string, projectIds: Array<string>, fullSync?: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).syncTodoTasksByProjects(userId, timezone, projectIds, fullSync, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a complete status of a task
     * @param {string} taskId 
     * @param {string} boardId 
     * @param {string} userId 
     * @param {boolean} completed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateCompleteStatusOfTask(taskId: string, boardId: string, userId: string, completed: boolean, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateCompleteStatusOfTask(taskId, boardId, userId, completed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a label
     * @param {string} taskLabelGroupId 
     * @param {string} taskLabelId 
     * @param {object} body Partial&lt;TaskLabel&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskLabel(taskLabelGroupId: string, taskLabelId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskLabel(taskLabelGroupId, taskLabelId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a todo task
     * @param {string} taskId 
     * @param {string} boardId 
     * @param {string} userId 
     * @param {object} body Partial&lt;FirestoreTaskItem&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTodoTask(taskId: string, boardId: string, userId: string, body: object, options?: RawAxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTodoTask(taskId, boardId, userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



