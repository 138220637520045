import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEventMetrics } from '@demind-inc/core';

interface UseUpdateCalendarEventEnergyParams {
  calendarId: string;
  eventId: string;
  eventMetrics: CalendarEventMetrics;
  mutateParams?: {
    date?: string;
  };
}

export function useUpdateCalendarEventEnergy() {
  const queryClient = useQueryClient();

  const updateCalendarEventEnergyMutation = useMutation({
    mutationFn: ({ calendarId, eventId, eventMetrics }: UseUpdateCalendarEventEnergyParams) => {
      return calendarApi.updateCalendarEventEnergy(calendarId, eventId, eventMetrics);
    },
    onSuccess: (_, { mutateParams }) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });

      queryClient.invalidateQueries({
        queryKey: mutateParams?.date
          ? ['lifestack.circadian', { date: mutateParams.date }]
          : ['lifestack.circadian'],
      });
    },
  });

  return {
    updateCalendarEventEnergy: updateCalendarEventEnergyMutation.mutateAsync,
    ...updateCalendarEventEnergyMutation,
  };
}
