import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import dayjs from 'dayjs';

import {
  useDeleteCalendarEvent,
  useUpdateCalendarEvent,
  useUpdateCalendarEventEnergy,
  useUpdateTodoTask,
} from '../mutations';
import { useAuthContext } from './AuthProvider';
import { eventsSnackBarAtom } from '../recoil';
import { RBCEvent } from '../../components';
import { CalendarEventModifyOption, EventDetailsMenuMode } from '../types';
import { useCalendarContext } from './CalendarProvider';
import { useAIScheduler, useEnergyBoost, useMetricsActivitiesInCalendar } from '../../hooks';
import {
  getEventDateYYYYMMDD,
  isTemporalAutoScheduledEvent,
  removeScheduledTaskTimeFromTask,
} from '../../helpers';
import { CalendarEventMetrics } from '@demind-inc/core';
import { trackEventMixpanel } from '../../utils';

interface IEventDetailsMenuContext {
  selectedEvent?: RBCEvent;
  visibleMenuMode?: EventDetailsMenuMode;
  forceEnableToSave?: boolean;
  handleSaveEvent: (newEvent: CalendarEventModifyOption) => void;
  handleDeleteEvent: (event: RBCEvent) => void;
  handleSelectEvent: (event: RBCEvent) => void;
  handleStartEditingEvent: () => void;
  handleEnergySave: (eventMetric: CalendarEventMetrics) => void;
  clearSelectedEvent: () => void;
}

export const EventDetailsMenuContext = createContext({} as IEventDetailsMenuContext);
export const useEventDetailsMenuContext = () => useContext(EventDetailsMenuContext);

export const EventDetailsMenuProvider = ({ children }: { children: ReactNode }) => {
  const [selectedEvent, setSelectedEvent] = useState<RBCEvent>();
  const [visibleMenuMode, setVisibleMenuMode] = useState<EventDetailsMenuMode | undefined>();
  const [forceEnableToSave, setForceEnableToSave] = useState(false);

  const { onClickScheduledEnergyboost } = useEnergyBoost();
  const { handleAcceptAIScheduleEvent, handleDiscardEvent: handleDiscardAIScheduledEvent } =
    useAIScheduler();
  const { handleSelectActivityEvent } = useMetricsActivitiesInCalendar();
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);
  const { user } = useAuthContext();
  const { updateTodoTask } = useUpdateTodoTask();

  const { updateCalendarEvent, status: eventUpdatingStatus } = useUpdateCalendarEvent();
  const { deleteCalendarEvent, status: eventDeletingStatus } = useDeleteCalendarEvent();
  const { mainCalendar, calendarAccountsToCreateEvents } = useCalendarContext();
  const { updateCalendarEventEnergy } = useUpdateCalendarEventEnergy();

  useEffect(() => {
    if (eventDeletingStatus === 'success') {
      setEventsSnackbar('Event deleted');
    } else if (eventDeletingStatus === 'pending') {
      setEventsSnackbar('Event deleting...');
    } else if (eventDeletingStatus === 'error') {
      setEventsSnackbar('Failed to delete event');
    }
  }, [eventDeletingStatus]);

  useEffect(() => {
    if (eventUpdatingStatus === 'success') {
      setEventsSnackbar('Event updated');
    } else if (eventUpdatingStatus === 'pending') {
      setEventsSnackbar('Event updating...');
    } else if (eventUpdatingStatus === 'error') {
      setEventsSnackbar('Failed to update event');
    }
  }, [eventUpdatingStatus]);

  const handleDeleteEvent = (event: RBCEvent) => {
    if (!event.calendarId) {
      return;
    }
    if (isTemporalAutoScheduledEvent(event.eventId, ['aiScheduler'])) {
      if (event.autoScheduleFrom?.id) {
        handleDiscardAIScheduledEvent(event.autoScheduleFrom.id);
        clearSelectedEvent();
        return;
      }
    }

    deleteCalendarEvent({
      userId: user.userId,
      calendarId: event.calendarId,
      eventId: event.eventId,
      unSyncToCalendar: event.unSyncToCalendar,
    });
    clearSelectedEvent();
    if (event.taskFrom) {
      updateTodoTask({
        userId: user.userId,
        boardId: event.taskFrom.boardId,
        taskId: event.taskFrom.taskId,
        newTaskInfo: {
          ...removeScheduledTaskTimeFromTask(dayjs(event.start).format('YYYY-MM-DD')),
          appFrom: event.taskFrom.from,
        },
      });
    }
  };

  const handleSelectEvent = (event: RBCEvent) => {
    setForceEnableToSave(false);
    if (isTemporalAutoScheduledEvent(event?.eventId, ['activity', 'aiScheduler'])) {
      setForceEnableToSave(true); // Force enable save button for temporal activity and ai scheduler events
    }
    if (isTemporalAutoScheduledEvent(event?.eventId, ['energyBoost'])) {
      setVisibleMenuMode('energy_boost');
      onClickScheduledEnergyboost(event);
      return;
    }
    if (isTemporalAutoScheduledEvent(event?.eventId, ['activity'])) {
      setVisibleMenuMode('activity');
      handleSelectActivityEvent(event.eventId);
      return;
    }
    setSelectedEvent(event);
    setVisibleMenuMode('edit');
  };

  const handleSaveEvent = (newEvent: CalendarEventModifyOption) => {
    if (!selectedEvent) {
      return;
    }

    if (isTemporalAutoScheduledEvent(selectedEvent.eventId, ['aiScheduler'])) {
      handleAcceptAIScheduleEvent(newEvent);
      clearSelectedEvent();
      return;
    }

    const hasCalendarAccess = calendarAccountsToCreateEvents.some(
      ({ calendarId }) => calendarId === selectedEvent.calendarId
    );

    if (!hasCalendarAccess) {
      return;
    }

    updateCalendarEvent({
      userId: user.userId,
      calendarId: selectedEvent.calendarId ?? mainCalendar?.calendarId!,
      eventId: selectedEvent.eventId,
      newEventOption: newEvent,
    });
    clearSelectedEvent();
  };

  const handleEnergySave = async (eventMetrics: CalendarEventMetrics) => {
    if (!selectedEvent?.eventId || ['temporal', 'creating'].includes(selectedEvent.eventId)) {
      return;
    }
    updateCalendarEventEnergy({
      calendarId: selectedEvent.calendarId ?? mainCalendar?.calendarId!,
      eventId: selectedEvent.eventId,
      eventMetrics: eventMetrics!,
      mutateParams: {
        date: dayjs(selectedEvent.start).format('YYYY-MM-DD'),
      },
    });
    trackEventMixpanel('answer_energy_feedback', {
      from: 'event_sheet',
      energyMetrics: eventMetrics,
    });
  };

  const handleStartEditingEvent = () => {
    setVisibleMenuMode('edit');
  };

  const clearSelectedEvent = () => {
    setSelectedEvent(undefined);
    setVisibleMenuMode(undefined);
  };

  return (
    <EventDetailsMenuContext.Provider
      value={{
        selectedEvent,
        visibleMenuMode,
        forceEnableToSave,
        handleSaveEvent,
        handleSelectEvent,
        handleDeleteEvent,
        clearSelectedEvent,
        handleEnergySave,
        handleStartEditingEvent,
      }}
    >
      {children}
    </EventDetailsMenuContext.Provider>
  );
};
