import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { TasksByDate } from '../types';
import { TaskItem } from '@demind-inc/core';
import { getIsOverdue } from '../../helpers';

interface UseUpdateTodoTaskParams {
  taskId: string;
  completed: boolean;
  boardId: string;
  userId: string;
  mutateParams?: {
    date?: string;
  };
}
export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();

  const updateTaskMutation = useMutation({
    mutationFn: async ({ taskId, completed, boardId, userId }: UseUpdateTodoTaskParams) => {
      return tasksApi
        .updateCompleteStatusOfTask(taskId, boardId, userId, completed)
        .then(({ data }) => data);
    },
    onMutate: async ({ taskId, completed, mutateParams }) => {
      const isOverdue = mutateParams?.date ? getIsOverdue(mutateParams?.date) : false;
      if (isOverdue) {
        await queryClient.cancelQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) =>
            prevTasks?.map((task) => (task.taskId === taskId ? { ...task, completed } : task))
        );
      } else {
        const queryKey = mutateParams?.date
          ? ['lifestack.todo.tasks', { dueDate: mutateParams.date }]
          : ['lifestack.todo.tasks'];
        await queryClient.cancelQueries({ queryKey });
        queryClient.setQueriesData<TasksByDate>({ queryKey }, (prevTasks) => ({
          date: prevTasks?.date,
          tasks: prevTasks?.tasks?.map((task) =>
            task.taskId === taskId ? { ...task, completed } : task
          ),
        }));
      }
    },
    onSuccess: (_, { mutateParams }) => {
      const isOverdue = mutateParams?.date ? getIsOverdue(mutateParams?.date) : false;
      if (isOverdue) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
      } else {
        queryClient.invalidateQueries({
          queryKey: mutateParams?.date
            ? ['lifestack.todo.tasks', { dueDate: mutateParams.date }]
            : ['lifestack.todo.tasks'],
        });
      }
    },
  });

  return {
    updateTodoTaskStatus: updateTaskMutation.mutateAsync,
    isTaskStatusUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};
