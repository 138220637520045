import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CalendarEvent, CalendarEventMetrics } from '@demind-inc/core';
import { cloneDeep } from 'lodash';

import { calendarApi } from '../api';
import { supplyCreateEventOption } from '../helpers';
import { CalendarEventCreateOption } from '../types';
import { useRecoilValue } from 'recoil';
import { syncedDatesState } from '../recoil';
import { getEventDateYYYYMMDD } from '../../helpers';

interface UseCreateCalendarEventV2Params {
  calendarId: string;
  userId: string;
  newEventOption: CalendarEventCreateOption;
  taskId?: string;
  metrics?: CalendarEventMetrics;
}

export function useCreateCalendarEvent() {
  const queryClient = useQueryClient();
  const targetSyncedDates = useRecoilValue(syncedDatesState);
  const getQueryKey = (userId: string) => [
    'calendar.events',
    {
      userId,
      startDate: targetSyncedDates[0].startOf('day').toISOString(),
      endDate: targetSyncedDates[targetSyncedDates.length - 1].endOf('day').toISOString(),
    },
  ];

  const createCalendarEventMutation = useMutation({
    mutationFn: ({
      calendarId,
      userId,
      newEventOption,
      taskId,
      metrics,
    }: UseCreateCalendarEventV2Params) => {
      return calendarApi.createCalendarEvent(calendarId, userId, {
        taskId,
        metrics,
        newEventOption,
      });
    },
    onMutate: async ({ userId, calendarId, newEventOption }: UseCreateCalendarEventV2Params) => {
      const prevQueryEvents =
        queryClient.getQueriesData<CalendarEvent[]>({
          queryKey: getQueryKey(userId),
        })?.[0]?.[1] ?? [];
      await queryClient.cancelQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: ['lifestack.calendar.events'] },
        (prevEvents) => {
          const suppliedNewEvent = supplyCreateEventOption(calendarId, newEventOption);
          return cloneDeep([...(prevEvents ?? []), suppliedNewEvent]);
        }
      );
      return { prevQueryEvents };
    },
    onSuccess: (_, { metrics, newEventOption }) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });

      if (metrics?.energy) {
        const eventDate = getEventDateYYYYMMDD(newEventOption);

        queryClient.invalidateQueries({
          queryKey: eventDate
            ? ['lifestack.circadian', { date: eventDate }]
            : ['lifestack.circadian'],
        });
      }
    },
    onError: (_, { userId }, context) => {
      if (context?.prevQueryEvents?.length) {
        queryClient.setQueriesData<CalendarEvent[]>(
          { queryKey: getQueryKey(userId) },
          context.prevQueryEvents
        );
      }
    },
  });

  return {
    createCalendarEvent: createCalendarEventMutation.mutate,
    ...createCalendarEventMutation,
  };
}
