import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge, unionBy } from 'lodash';
import { TaskItem, TodoIntegrationType } from '@demind-inc/core';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { TaskError401Res, tasksApi } from '../api';
import { FirestoreTaskItem, TasksByDate } from '../types';
import { taskErrorSnackBarAtom } from '../recoil';
import { getDueDateYYYYMMDD, getIsOverdue } from '../../helpers';

interface MovedTaskParam {
  prevDate?: string; //YYYY-MM-DD
  newDate?: string; //YYYY-MM-DD
  wasOverdue?: boolean;
}

interface UseUpdateTodoTaskParams {
  boardId: string;
  taskId: string;
  userId: string;
  newTaskInfo: Partial<FirestoreTaskItem>;
  movedTaskParam?: MovedTaskParam; // Used for updating the state while mutating between dates
}

export const useUpdateTodoTask = () => {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const updateTaskMutation = useMutation({
    mutationFn: ({ boardId, taskId, userId, newTaskInfo }: UseUpdateTodoTaskParams) => {
      return tasksApi.updateTodoTask(taskId, boardId, userId, newTaskInfo).then(({ data }) => data);
    },
    onMutate: async ({ taskId, newTaskInfo, movedTaskParam }) => {
      const dueDate = getDueDateYYYYMMDD(newTaskInfo.dueDateTime);

      if (getIsOverdue(dueDate)) {
        await queryClient.cancelQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) =>
            prevTasks?.map((task) => (task.taskId === taskId ? merge({}, task, newTaskInfo) : task))
        );
        return;
      }
      await queryClient.cancelQueries({
        queryKey: dueDate ? ['lifestack.todo.tasks', { dueDate }] : ['lifestack.todo.tasks'],
      });
      queryClient.setQueriesData<TasksByDate>(
        { queryKey: ['lifestack.todo.tasks'] },
        (prevTasks) => {
          const prevTaskDate = prevTasks?.date;

          let updatedTasks = prevTasks?.tasks?.map((task) =>
            task.taskId === taskId ? merge({}, task, newTaskInfo) : task
          );

          // Update the state of the task when moving between dates
          if (!!movedTaskParam && movedTaskParam.prevDate === prevTaskDate) {
            updatedTasks = updatedTasks?.filter((task) => task.taskId !== taskId);
          }
          if (!!movedTaskParam && movedTaskParam.newDate === prevTaskDate) {
            const addedTask = { ...newTaskInfo, taskId } as TaskItem;
            updatedTasks = unionBy(updatedTasks, [addedTask], 'taskId');
          }

          return {
            date: prevTaskDate,
            tasks: updatedTasks,
          };
        }
      );

      // Update overdue tasks
      //FIXME: wasOverDue returns false even in case of moving overdue task
      if (movedTaskParam?.wasOverdue) {
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) => prevTasks?.filter((task) => task.taskId !== taskId)
        );
      }
    },
    onSuccess: (_, { newTaskInfo, movedTaskParam }) => {
      const dueDate = getDueDateYYYYMMDD(newTaskInfo.dueDateTime);

      if (getIsOverdue(dueDate)) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        return;
      }

      if (movedTaskParam) {
        queryClient.invalidateQueries({
          queryKey: ['lifestack.todo.tasks', { dueDate: movedTaskParam.prevDate }],
        });
        if (movedTaskParam.prevDate !== movedTaskParam.newDate) {
          queryClient.invalidateQueries({
            queryKey: ['lifestack.todo.tasks', { dueDate: movedTaskParam.newDate }],
          });
        }
        if (movedTaskParam.wasOverdue) {
          queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        }
        return;
      } else {
        queryClient.invalidateQueries({
          queryKey: dueDate ? ['lifestack.todo.tasks', { dueDate }] : ['lifestack.todo.tasks'],
        });
      }
    },
  });

  useEffect(() => {
    const error = (updateTaskMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'update',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to update task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'update',
    });
  }, [updateTaskMutation.error]);

  return {
    updateTodoTask: updateTaskMutation.mutateAsync,
    isUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};
