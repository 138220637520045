import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@mui/lab';
import { SchedulerPreference } from '@demind-inc/core';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { AccessTime, Settings } from '@mui/icons-material';
import { useRecoilState } from 'recoil';

import './AISchedulerModalView.scss';
import {
  initialDefaultPrefItemState,
  prefItemReducer,
  transformDailyPrefForRunEndpoint,
  transformPrefToPrefeditState,
  combineAutoAdjustmentPref,
  AutoTimeboxAdjustmentLevel,
  autoTimeboxAdjustmentText,
  DailyPrefEditItem,
  autoTimeboxAdjustmentColor,
  schedulerPrefTitleSet,
  schedulerPrefSwitchableOptions,
  SchedulerPrefTimeRange,
  SchedulerPrefDurationRange,
  NoScheduleReason,
  NoScheduleCard,
  validatePreferenceItems,
} from '../../../../../Scheduler';
import {
  AISchedulerVersion,
  getDiffMinBetweenHHmm,
  selectedDateAtom,
  settingsModalAtom,
  useAIPlannerTimeFrame,
} from '../../../../../../data-access';
import { getCssVariable, useFeatureFlag } from '../../../../../../utils';
import { formatTimeFromHHMM } from '../../../../../../helpers';
import { useGeneralSettings } from '../../../../../../hooks';
import { ToggleButtons } from '../../../../../common';

interface AISchedulerDailyPrefModalViewProps {
  defaultPref?: SchedulerPreference;
  targetVersion: AISchedulerVersion;
  onRunAIScheduler: (preferences: SchedulerPreference) => void;
  onToggleTargetVersion: (version: AISchedulerVersion) => void;
}

const BLACK_COLOR = getCssVariable('--color-black');

const targetVersionOptions = [
  { value: 'timebox' as const, label: 'Timebox' },
  { value: 'todobox' as const, label: 'Task' },
];

const timeboxExplanation =
  'Lifestack will suggest an optimal sequence of events considering your preferences and energy for the day.';
const todoboxExplanation =
  'Based on our timebox recommendation, Lifestack will suggest which todos to do when!';

const AISchedulerDailyPrefModalView: React.FC<AISchedulerDailyPrefModalViewProps> = ({
  defaultPref,
  targetVersion,
  onToggleTargetVersion,
  onRunAIScheduler,
}) => {
  const [prefItems, dispatch] = useReducer(prefItemReducer, initialDefaultPrefItemState);
  const [isDailyPrefLoading, setIsDailyPrefLoading] = useState(false);
  const [autoTimeboxAdjustCondition, setAutoTimeboxAdjustCondition] =
    useState<AutoTimeboxAdjustmentLevel>(AutoTimeboxAdjustmentLevel.AVERAGE);
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const hasInitPref = useRef(false);

  const { calcAIPlannerTimeFrame } = useAIPlannerTimeFrame();
  const [_, setSettingsModalVisible] = useRecoilState(settingsModalAtom);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const {
    featureFlags: { FF_ai_task_scheduler_v2 = false },
  } = useFeatureFlag();

  const validationError = useMemo(() => validatePreferenceItems(prefItems), [prefItems]);

  const { canSchedule, noScheduleReason } = useMemo(() => {
    if (!prefItems.workTime.end.time) {
      return { canSchedule: true };
    }
    if (selectedDate.isBefore(dayjs(), 'date')) {
      return { canSchedule: false, noScheduleReason: 'pastday' as NoScheduleReason };
    }
    if (selectedDate.isAfter(dayjs(), 'date')) {
      return { canSchedule: true };
    }
    const isNotAfterWork =
      getDiffMinBetweenHHmm(dayjs().format('HH:mm'), prefItems.workTime.end.time) >= 0; // Check if the end time is in the future

    return { canSchedule: isNotAfterWork, noScheduleReason: 'afterWork' as NoScheduleReason };
  }, [prefItems, selectedDate]);

  // Initialize preference items
  useEffect(() => {
    const initPref = async () => {
      if (!defaultPref || isEmpty(defaultPref || hasInitPref.current)) {
        return;
      }
      setIsDailyPrefLoading(true);
      const transformedPref = transformPrefToPrefeditState(defaultPref);
      const timeFrames = await calcAIPlannerTimeFrame({
        deepworkDurationMin: transformedPref.deepWork.duration.durationMin,
        lunchDurationMin: transformedPref.lunch.duration.durationMin,
      });

      // Set preferences
      dispatch({
        type: 'initialize',
        payload: timeFrames
          ? combineAutoAdjustmentPref(timeFrames, transformedPref)
          : transformedPref,
      });

      // Set auto time frame condition for descriptions
      if (timeFrames?.deepworkDurationMin) {
        const isOverAverage =
          timeFrames.deepworkDurationMin > transformedPref.deepWork.duration.durationMin;
        setAutoTimeboxAdjustCondition(
          isOverAverage
            ? AutoTimeboxAdjustmentLevel.ABOVE_AVERAGE
            : AutoTimeboxAdjustmentLevel.BELOW_AVERAGE
        );
      } else {
        setAutoTimeboxAdjustCondition(AutoTimeboxAdjustmentLevel.AVERAGE);
      }
      setIsDailyPrefLoading(false);
      hasInitPref.current = true;
    };

    initPref();
  }, [defaultPref]);

  if (!canSchedule && !!noScheduleReason) {
    return (
      <div className="ai-scheduler-modal" style={{ height: '100%' }}>
        <NoScheduleCard
          reason={noScheduleReason}
          onChangeDate={() =>
            setSelectedDate((prev) =>
              noScheduleReason === 'afterWork' ? prev.add(1, 'day').startOf('day') : dayjs()
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="ai-scheduler-modal">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8 }}>
        <div
          className={clsx(
            'ai-scheduler-modal__left-section',
            'ai-scheduler-modal__left-section--bg'
          )}
        >
          <div className="ai-scheduler-modal__left-section__inside-container">
            <div className="ai-scheduler-modal__header">
              <div className="ai-scheduler-modal__header__title">
                Confirm your preferences today
              </div>
              {isDailyPrefLoading ? (
                <Skeleton animation="wave" variant="text" />
              ) : (
                <div className="ai-scheduler-modal__header__subtitle">
                  {autoTimeboxAdjustmentText[autoTimeboxAdjustCondition]}
                </div>
              )}
            </div>
            {!!validationError && (
              <div className="ai-scheduler-modal__validation">{validationError}</div>
            )}
            <div className="ai-scheduler-modal__pref-grid">
              {Object.entries(prefItems)
                .filter(([prefKey]) => prefKey !== 'workTime') // hide workTime
                .map(([prefKeyRaw, prefInfo]) => {
                  const prefKey = prefKeyRaw as keyof typeof prefItems;
                  const color =
                    'autoAdjusted' in prefInfo && prefInfo.autoAdjusted
                      ? autoTimeboxAdjustmentColor[autoTimeboxAdjustCondition]
                      : BLACK_COLOR;

                  return (
                    <DailyPrefEditItem
                      key={prefKey}
                      isRange={['workout', 'lunch', 'dinner'].includes(prefKey)}
                      title={schedulerPrefTitleSet[prefKey]}
                      start={'start' in prefInfo ? prefInfo.start : undefined}
                      end={'end' in prefInfo ? prefInfo.end : undefined}
                      duration={'duration' in prefInfo ? prefInfo.duration : undefined}
                      enabled={'enabled' in prefInfo ? prefInfo.enabled : true}
                      hasSwitch={schedulerPrefSwitchableOptions.includes(prefKey)}
                      color={color}
                      isLoading={isDailyPrefLoading}
                      onStartTimeChange={(startTime) =>
                        dispatch({
                          type: prefKey,
                          payload: {
                            start: {
                              ...(prefInfo as { start: SchedulerPrefTimeRange }).start,
                              time: startTime,
                            } as SchedulerPrefTimeRange,
                          },
                        })
                      }
                      onEndTimeChange={(endTime) =>
                        dispatch({
                          type: prefKey,
                          payload: {
                            end: {
                              ...(prefInfo as { end: SchedulerPrefTimeRange }).end,
                              time: endTime,
                            } as SchedulerPrefTimeRange,
                          },
                        })
                      }
                      onDurationChange={(durationMin) =>
                        dispatch({
                          type: prefKey,
                          payload: {
                            duration: {
                              ...(prefInfo as { duration: SchedulerPrefDurationRange }).duration,
                              durationMin: durationMin,
                            } as SchedulerPrefDurationRange,
                          },
                        })
                      }
                      onSwitchChange={(enabled) =>
                        dispatch({
                          type: prefKey,
                          payload: {
                            enabled,
                          },
                        })
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="ai-scheduler-modal__footer">
          <div className="ai-scheduler-modal__footer__right-group">
            <div className="ai-scheduler-modal__footer__item">
              <AccessTime className="ai-scheduler-modal__footer__item__icon" />
              Work hours: {formatTimeFromHHMM(prefItems.workTime.start.time, timeFormat)} -{' '}
              {formatTimeFromHHMM(prefItems.workTime.end.time, timeFormat)}
            </div>
            <div
              className="ai-scheduler-modal__footer__item"
              onClick={() =>
                setSettingsModalVisible({ visible: true, defaultPosition: 'aiScheduler' })
              }
            >
              <Settings className="ai-scheduler-modal__footer__item__icon" />
              Change your default preferences
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('ai-scheduler-modal__description-container')}>
        <p className="ai-scheduler-modal__description">
          Set up how much you want to achieve today, and let AI Scheduler optimize your schedule
          based on your energy and preferences.
        </p>
        {FF_ai_task_scheduler_v2 && (
          <div className="ai-scheduler-modal__version-toggle">
            <div className="ai-scheduler-modal__version-toggle__explanation">
              {targetVersion === 'timebox' ? (
                <p className="ai-scheduler-modal__version-toggle__explanation__text">
                  {timeboxExplanation}
                </p>
              ) : (
                <>
                  <p className="ai-scheduler-modal__version-toggle__explanation__text">
                    {todoboxExplanation}
                  </p>
                  <p className="ai-scheduler-modal__version-toggle__explanation__caption">
                    *Only tasks labeled "Deep Work" or "Light Task" will be scheduled
                  </p>
                </>
              )}
            </div>
            <div className="ai-scheduler-modal__version-toggle__content">
              <p className="ai-scheduler-modal__version-toggle__content__title">Schedule</p>
              <ToggleButtons
                value={targetVersion}
                options={targetVersionOptions}
                onChange={onToggleTargetVersion}
                className="ai-scheduler-modal__version-toggle__content__buttons"
              />
            </div>
          </div>
        )}
        <Button
          onClick={async () => {
            const transformedPref = transformDailyPrefForRunEndpoint(prefItems);
            await onRunAIScheduler(transformedPref);
          }}
          disabled={isDailyPrefLoading || !!validationError}
          className={clsx(
            'core-action-modal__button',
            (isDailyPrefLoading || !!validationError) && 'ai-scheduler-modal__run-button--disabled'
          )}
        >
          Run
        </Button>
      </div>
    </div>
  );
};

export default AISchedulerDailyPrefModalView;
