import { useMemo, useState } from 'react';
import {
  syncedDatesState,
  useAuthContext,
  useSyncCalEventsApi,
  useSyncTodosApi,
} from '../data-access';
import { useRecoilValue } from 'recoil';

export const useRefreshData = () => {
  const { reSyncCalEvents } = useSyncCalEventsApi();
  const { reSyncTasks, isSyncingTodos: isReSyncingTodos } = useSyncTodosApi();
  const { user } = useAuthContext();
  const syncedDates = useRecoilValue(syncedDatesState);

  const { startTime, endTime } = useMemo(() => {
    const startTime = syncedDates[0].startOf('day').toISOString();
    const endTime = syncedDates[syncedDates.length - 1].endOf('day').toISOString();

    return { startTime, endTime };
  }, [syncedDates]);

  const refreshData = async () => {
    const calendarPromise = user.calendarIds?.length
      ? reSyncCalEvents(user.calendarIds, startTime, endTime)
      : Promise.resolve();

    const taskPromise = reSyncTasks(user.todoTasksBoardIds ?? []);

    await Promise.allSettled([calendarPromise, taskPromise]);
  };

  return { isReSyncingTodos, refreshData };
};
