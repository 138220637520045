import {
  CalendarEvent,
  defaultEventCategorySet,
  MetricActivity,
  ScheduledAction,
  ScheduledActionOptions,
  TaskItem,
} from '@demind-inc/core';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import { v4 as uuidv4 } from 'uuid';
import { ActivityCalendarEvent, MetricActivityType } from '../data-access';
import { upperFirst } from 'lodash';

dayjs.extend(tz);

export const ACTIVITY_EVENT_PREFIX = 'activity';
export const ENERGY_BOOST_EVENT_PREFIX = 'energy_boost';
export const AI_SCHEDULER_EVENT_PREFIX = 'ai_scheduler';
const exerciseCategory = defaultEventCategorySet.find(({ id }) => id === 'default_exercise');
const mealCategory = defaultEventCategorySet.find(({ id }) => id === 'default_meal');

export const convertTaskToEvent = (task: TaskItem): CalendarEvent => {
  const categories = task.labels
    ?.map((label) => {
      const matchedCategory = defaultEventCategorySet.find(({ name }) => name === label.name);
      return matchedCategory;
    })
    .filter((i) => !!i);

  return {
    eventId: 'creating',
    summary: task.name,
    description: task.desc,
    color: task.labels?.[0]?.color,
    start: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: '',
      timeZone: dayjs.tz.guess(),
    },
    taskFrom: {
      taskId: task.taskId,
      boardId: task.boardId!,
      from: task.appFrom,
    },
    categories,
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
  };
};

/**
 * Translates the scheduled action options to a temporal calendar event.
 * @param actionOptions - The scheduled action options.
 * @returns The translated calendar event.
 */
export const translateScheduledActionOptionsToTemporalCalendarEvent = (
  actionOptions: ScheduledActionOptions
): CalendarEvent => {
  return {
    eventId: `${ENERGY_BOOST_EVENT_PREFIX}_${actionOptions.startDate}`,
    description: 'Energy-boosting event, created from Lifestack (https://lifestack.ai).',
    summary: '[Energy Boost]',
    start: {
      date: actionOptions.startDate,
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: actionOptions.endDate,
      timeZone: dayjs.tz.guess(),
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
    scheduleFrom: 'energyBoost',
  };
};

/**
 * Translates a ScheduledAction object to a CalendarEvent object.
 * @param action - The ScheduledAction object to be translated.
 * @returns The translated CalendarEvent object.
 */
export const translateScheduledActionToCalendarEvent = (action: ScheduledAction): CalendarEvent => {
  return {
    eventId: (uuidv4() as string).replace(/-/g, ''),
    summary: action.name,
    description: action.description!,
    start: {
      date: action.startDate,
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: action.endDate,
      timeZone: dayjs.tz.guess(),
    },
    status: 'confirmed',
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
    eventMetrics: {
      energy: 'high',
    },
    recommendedTaskId: action.taskId,
    scheduleFrom: 'energyBoost',
  };
};

/**
 * Translates a MetricActivity object to a CalendarEvent object.
 *
 * @param activity - The MetricActivity object to be translated.
 * @returns A CalendarEvent object containing the translated activity details.
 */
export const translateActivityToTemporalCalendarEvent = (
  activity: MetricActivity,
  activityType: MetricActivityType
): ActivityCalendarEvent => {
  const category = activityType === 'exercise' ? exerciseCategory : mealCategory;

  return {
    eventId: `${ACTIVITY_EVENT_PREFIX}_${activity.activityId}`,
    summary: activity.name,
    description: `${upperFirst(activityType)} event, created from Lifestack (https://lifestack.ai).`,
    start: {
      date: dayjs(activity.startTime).toISOString(),
      timeZone: dayjs.tz.guess(),
    },
    end: {
      date: dayjs(activity.endTime).toISOString(),
      timeZone: dayjs.tz.guess(),
    },
    updatedDate: dayjs().toISOString(),
    createdDate: dayjs().toISOString(),
    eventMetrics: {
      energy: 'high',
    },
    categories: category ? [category] : [],
    activityFrom: {
      activityId: activity.activityId,
    },
    scheduleFrom: 'activity',
    source: activity.source!,
    activityType,
  };
};

export const getEventDateYYYYMMDD = (event: Partial<CalendarEvent>) => {
  if (!event.start?.date) {
    return;
  }
  return dayjs(event.start.date).format('YYYY-MM-DD');
};
