import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { TaskItem, TaskLabel, TodoTasksBoard } from '@demind-inc/core';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';

import { useAuthContext } from './AuthProvider';
import { useOverdueTasks, useTaskLabels, useTodoProjects, useTodoTasks } from '../queries';
import { projectFilterAtom, taskFilterAtom } from '../recoil';
import { TasksByDate } from '../types';
import { getDateRange } from '../../helpers';

interface ITodoTasksContext {
  todoProjects: TodoTasksBoard[];
  todoTasksByDate: TasksByDate[];
  overdueTasks: TaskItem[];
  visibleTodoListDates: Dayjs[];
  taskLabels: TaskLabel[];
  isFetchingProjects: boolean;
  isFetchingTasks: boolean;
  isFetchingOverdueTasks: boolean;
  findProject: (projectId: string) => TodoTasksBoard | undefined;
  visibleProjectIds: string[];
  loadMoreTasks: () => void;
}

export const TodoTasksContext = createContext({} as ITodoTasksContext);
export const useTodoTasksContext = () => useContext(TodoTasksContext);

const numOfDaysForTasksList = 3;
export const overDueDateTill = dayjs().subtract(1, 'day'); // Get overdue tasks till yesterday

export const TodoTasksProvider = ({ children }: { children: ReactNode }) => {
  const selectedProjectFilters = useRecoilValue(projectFilterAtom);
  const taskFilter = useRecoilValue(taskFilterAtom);
  const currentStartDate = dayjs();
  const [currentEndDate, setCurrentEndDate] = useState(
    dayjs().add(numOfDaysForTasksList - 1, 'day')
  );

  const loadMoreTasks = () => {
    setCurrentEndDate((prev) => prev.add(numOfDaysForTasksList, 'day'));
  };

  const { user } = useAuthContext();
  const { todoProjects, isLoading: isFetchingProjects } = useTodoProjects({ userId: user.userId! });
  const { taskLabels } = useTaskLabels({ taskLabelGroupId: user.taskLabelsGroupId });

  const visibleProjectIds = useMemo(() => {
    if (selectedProjectFilters?.find((p) => p.todoTaskId === 'all')) {
      return (user?.todoTasksBoardIds || []).filter((boardId) => {
        const project = todoProjects.find((p) => p.todoTaskId === boardId);
        return !project?.closed && !!project?.name;
      });
    }
    return selectedProjectFilters?.length
      ? selectedProjectFilters.map((item) => item.todoTaskId)
      : [];
  }, [selectedProjectFilters, user?.todoTasksBoardIds, todoProjects]);

  const visibleTodoListDates = useMemo(
    () => getDateRange({ startDate: currentStartDate, endDate: currentEndDate }),
    [currentEndDate]
  );
  const { todoTasksByDate, isLoading: isFetchingTasks } = useTodoTasks({
    todoTasksBoardIds: visibleProjectIds ?? [],
    dates: visibleTodoListDates.map((d) => d.format('YYYY-MM-DD')),
    filter: {
      labelNames: taskFilter.labelNames,
    },
  });
  const { data: overdueTasks, isLoading: isFetchingOverdueTasks } = useOverdueTasks({
    visibleProjectIds,
    dueDateTill: overDueDateTill.format('YYYY-MM-DD'),
    filter: {
      labelNames: taskFilter.labelNames,
      hideNoDueDate: taskFilter.hideNoDueDate,
    },
  });

  const findProject = useCallback(
    (projectId: string) => {
      if (!todoProjects.length) {
        return;
      }
      const project = todoProjects.find((project) => project.todoTaskId === projectId);
      return project;
    },
    [todoProjects.length]
  );

  return (
    <TodoTasksContext.Provider
      value={{
        todoProjects,
        todoTasksByDate,
        overdueTasks,
        visibleTodoListDates,
        taskLabels,
        isFetchingProjects,
        isFetchingTasks,
        isFetchingOverdueTasks,
        findProject,
        visibleProjectIds,
        loadMoreTasks,
      }}
    >
      {children}
    </TodoTasksContext.Provider>
  );
};
