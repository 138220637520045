import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { TaskItem, TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';

import { TaskError401Res, tasksApi } from '../api';
import { AddTodoTaskItemProps, TasksByDate } from '../types';
import { taskErrorSnackBarAtom } from '../recoil';
import { getDueDateYYYYMMDD } from '../../helpers';

interface UseAddTodoTaskParams {
  boardId: string;
  userId: string;
  newTaskInfo: AddTodoTaskItemProps;
}

export const useAddTodoTask = () => {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const addTaskMutation = useMutation({
    mutationFn: ({ boardId, userId, newTaskInfo }: UseAddTodoTaskParams) => {
      return tasksApi.addTodoTask(boardId, userId, newTaskInfo).then(({ data }) => data.taskId);
    },
    onMutate: async ({ boardId, userId, newTaskInfo }) => {
      const dueDate = getDueDateYYYYMMDD(newTaskInfo.dueDateTime);
      if (!dueDate) {
        return;
      }

      const queryKey = ['lifestack.todo.tasks', { dueDate }];
      await queryClient.cancelQueries({
        queryKey,
      });
      queryClient.setQueriesData<TasksByDate>({ queryKey }, (prevTasks) => {
        return {
          date: prevTasks?.date,
          tasks: [...(prevTasks?.tasks ?? []), newTaskInfo] as TaskItem[],
        };
      });
    },
    onSuccess: (_, { newTaskInfo }) => {
      const dueDate = getDueDateYYYYMMDD(newTaskInfo.dueDateTime);

      queryClient.invalidateQueries({
        queryKey: dueDate ? ['lifestack.todo.tasks', { dueDate }] : ['lifestack.todo.tasks'],
      });
    },
  });

  useEffect(() => {
    const error = (addTaskMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'add',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to add task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'add',
    });
  }, [addTaskMutation.error]);

  return {
    addTodoTask: addTaskMutation.mutateAsync,
    ...addTaskMutation,
  };
};
