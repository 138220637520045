import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TaskError401Res, tasksApi } from '../api';
import { useRecoilState } from 'recoil';
import { taskErrorSnackBarAtom } from '../recoil';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { TaskItem, TodoIntegrationType } from '@demind-inc/core';
import { TasksByDate } from '../types';

interface UseDeleteTaskProps {
  taskId: string;
  boardId: string;
  userId: string;
  mutateParam?: {
    date: string;
    isOverdue: boolean;
  };
}

export function useDeleteTask() {
  const queryClient = useQueryClient();
  const [_, setTaskErrorSnackbar] = useRecoilState(taskErrorSnackBarAtom);

  const deleteTaskMutation = useMutation({
    mutationFn: ({ taskId, boardId, userId }: UseDeleteTaskProps) => {
      return tasksApi.deleteTodoTask(taskId, boardId, userId);
    },
    onMutate: async ({ taskId, mutateParam }: UseDeleteTaskProps) => {
      if (!mutateParam) {
        return;
      }
      if (mutateParam.isOverdue) {
        await queryClient.cancelQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        queryClient.setQueriesData<TaskItem[]>(
          { queryKey: ['lifestack.todo.overdueTasks'] },
          (prevTasks) => prevTasks?.filter((task) => task.taskId !== taskId)
        );
      } else {
        const queryKey = ['lifestack.todo.tasks', { dueDate: mutateParam.date }];
        await queryClient.cancelQueries({
          queryKey,
        });
        queryClient.setQueriesData<TasksByDate>({ queryKey }, (prevTasks) => {
          return {
            date: prevTasks?.date,
            tasks: prevTasks?.tasks?.filter((task) => task.taskId !== taskId),
          };
        });
      }
    },
    onSuccess: (_, { mutateParam }) => {
      if (!mutateParam) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
        queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
        return;
      }

      if (mutateParam.isOverdue) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
      } else {
        const queryKey = ['lifestack.todo.tasks', { dueDate: mutateParam.date }];

        queryClient.invalidateQueries({ queryKey });
      }
    },
  });

  useEffect(() => {
    const error = (deleteTaskMutation.error as AxiosError)?.response;
    if (!error) {
      return;
    }
    if (error.status === 401) {
      setTaskErrorSnackbar({
        status: error.status,
        message: 'Invalid token.',
        provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
        action: 'delete',
      });
      return;
    }
    setTaskErrorSnackbar({
      status: error.status,
      message: 'Failed to delete task.',
      provider: (error.data as TaskError401Res).provider as TodoIntegrationType,
      action: 'delete',
    });
  }, [deleteTaskMutation.error]);

  return {
    deleteTask: deleteTaskMutation.mutateAsync,
    isDeleting: deleteTaskMutation.isPending,
    ...deleteTaskMutation,
  };
}
