import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';
import { SleepDetailsByDate } from '@demind-inc/core';

interface UseSyncSleepDetailsParams {
  userId: string;
  metricId: string;
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export function useSyncSleepDetails() {
  const queryClient = useQueryClient();

  const syncSleepDetailMutation = useMutation({
    mutationFn: ({
      userId,
      metricId,
      terraUserIds,
      startDate,
      endDate,
    }: UseSyncSleepDetailsParams) => {
      return metricsApi
        .syncSleepDetails(metricId, userId, terraUserIds, startDate, endDate)
        .then(({ data }) => (data.results ?? []) as SleepDetailsByDate[]);
    },
    onSuccess: (data) => {
      if (!data.length) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['lifestack.sleep.details'] });
      data.forEach((item) => {
        const date = item.date;
        queryClient.invalidateQueries({ queryKey: ['lifestack.circadian', { date }] });
      });
    },
  });

  return {
    syncSleepDetail: syncSleepDetailMutation.mutateAsync,
    ...syncSleepDetailMutation,
  };
}
