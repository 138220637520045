import React from 'react';
import clsx from 'clsx';
import { DateCalendar } from '@mui/x-date-pickers';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocalStorage } from '@uidotdev/usehooks';
import { TaskItem } from '@demind-inc/core';
import dayjs, { Dayjs } from 'dayjs';

import './SideBar.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';
import {
  CalendarsList,
  SettingsBlock,
  GeneralCTA,
  TodoList,
  CTAMenu,
  CTAMenuType,
  ManualSyncButton,
} from '../../../components';
import {
  selectedDateAtom,
  syncErrorRootEmailAtom,
  useCalendarContext,
  useCreateEventMenuContext,
  visibleTaskDetailAtom,
} from '../../../data-access';
import { useConnectCalendar, useRefreshData } from '../../../hooks';
import { SIDEBAR_MENU } from './constants';
import { SideBarMinimized } from './SideBarMinimized';

interface SideBarProps {
  className?: string;
}

const SideBar: React.FC<SideBarProps> = ({ className }) => {
  const { calendarsMeta, visibleCalendarIds, isFetchingCalendars, handleSelectCalendar } =
    useCalendarContext();
  const syncErrorRootEmail = useRecoilValue(syncErrorRootEmailAtom);
  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateAtom);
  const [__, setVisibleTask] = useRecoilState(visibleTaskDetailAtom);
  const [open, setOpen] = useLocalStorage('sidebarOpen', true);
  const { connectCal } = useConnectCalendar();
  const { refreshData, isReSyncingTodos } = useRefreshData();

  if (!open) {
    return (
      <SideBarMinimized
        setOpen={setOpen}
        isRefreshing={isReSyncingTodos}
        onRefreshData={refreshData}
      />
    );
  }

  const { handleSelectNewEvent } = useCreateEventMenuContext();

  const handleCTASelect = (cta: CTAMenuType) => {
    if (cta === 'event') {
      handleSelectNewEvent({
        start: selectedDate.toISOString(),
        end: selectedDate.add(30, 'minutes').toISOString(),
      });
      return;
    } else if (cta === 'task') {
      setVisibleTask({
        dueDateTime: {
          date: selectedDate.format('YYYY-MM-DD'),
          timezone: dayjs.tz.guess(),
        },
      } as TaskItem);
      return;
    }
  };

  return (
    <div className={clsx('sidebar', className)}>
      <div className="sidebar__header">
        <img src={LifestackLogoName} alt="Lifestack Logo" className="sidebar__header__logo" />
        <button onClick={() => setOpen(false)} className="sidebar__header__menu-icon">
          <SIDEBAR_MENU.close title="Close Menu" />
        </button>
      </div>
      <div className="sidebar__content">
        <CTAMenu onClick={handleCTASelect} />
        <ManualSyncButton
          onSync={refreshData}
          isSyncing={isReSyncingTodos}
          className="sidebar__manual-sync"
        />
        <div className="sidebar__calendar">
          <DateCalendar
            value={selectedDate}
            onChange={(val: Dayjs) => setSelectedDate(val.startOf('day'))}
            className="sidebar__calendar__content"
          />
        </div>
        <CalendarsList
          calendarsMeta={calendarsMeta}
          visibleCalendarIds={visibleCalendarIds}
          isFetchingCalendars={isFetchingCalendars}
          syncErrorRootEmail={syncErrorRootEmail}
          onSelectCalendar={handleSelectCalendar}
          onConnectCalendar={connectCal}
          className="sidebar__calendars"
        />
        <TodoList className="sidebar__todos" />
        <GeneralCTA className="sidebar__general-cta" />
      </div>
      <SettingsBlock className="sidebar__settings" />
    </div>
  );
};

export default SideBar;
