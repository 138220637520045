import React from 'react';

import './NoScheduleCard.scss';
import { Button } from '@mui/material';
import { NoScheduleReason } from './types';

import NoScheduleImage from '../../../../assets/scheduler/no-schedule-creative.png';

interface NoScheduleCardProps {
  reason: NoScheduleReason;
  onChangeDate: () => void;
}

const NoScheduleCard: React.FC<NoScheduleCardProps> = ({ reason, onChangeDate }) => {
  const title = {
    afterWork: 'your work hours have ended.',
    pastday: "it's a past date.",
  }[reason];

  const ctaText = {
    afterWork: 'the following day',
    pastday: 'today',
  }[reason];

  return (
    <div className="no-schedule-card">
      <img src={NoScheduleImage} alt="No Schedule" className="no-schedule-card__image" />
      <h2 className="no-schedule-card__title">
        No plans could be scheduled for the selected day because {title} Would you like to schedule
        for {ctaText} instead?
      </h2>
      <Button variant="contained" onClick={onChangeDate} className="no-schedule-card__cta">
        Schedule for {ctaText}
      </Button>
    </div>
  );
};

export default NoScheduleCard;
