import { useQuery } from '@tanstack/react-query';
import { TodoTasksBoard } from '@demind-inc/core';

import { tasksApi } from '../api';

interface UseTodoProjectsParams {
  userId: string;
}

interface UseTodoProjectsQueryParams {
  queryKey: ['lifestack.todo.projects', UseTodoProjectsParams];
}

export const useTodoProjects = ({ userId }: UseTodoProjectsParams) => {
  const todoProjectsQuery = useQuery({
    queryKey: ['lifestack.todo.projects', { userId }],
    queryFn: ({ queryKey }: UseTodoProjectsQueryParams) => {
      const [, { userId }] = queryKey;
      return tasksApi.getTodoProjects(userId).then(({ data }) => data as TodoTasksBoard[]);
    },
    refetchOnWindowFocus: false,
    enabled: !!userId,
    staleTime: 15000, // 15s
  });

  return {
    todoProjects: (todoProjectsQuery.data ?? []).filter((i) => !!i?.name),
    ...todoProjectsQuery,
  };
};
