import React, { CSSProperties, memo, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { TaskItem } from '@demind-inc/core';
import { useRecoilState } from 'recoil';
import { useDrop } from 'react-dnd';

import './KanbanList.scss';
import { KanbanTaskCard } from '../KanbanTaskCard';
import {
  eventsSnackBarAtom,
  useAuthContext,
  useCircadianContext,
  useTodoTasksContext,
  useUpdateTaskStatus,
} from '../../../data-access';
import { CircularProgress } from '@mui/material';
import { trackEventMixpanel } from '../../../utils';
import { AddOutlined } from '@mui/icons-material';
import { KanbanListFilterItem, KanbanListFilterMenu } from './KanbanListFilterMenu';
import { calculateNewSortIndex, getDueDateYYYYMMDD, SORT_INDEX_INCREMENT } from '../../../helpers';
import { last } from 'lodash';

interface KanbanListProps {
  date: Dayjs;
  tasks: TaskItem[];
  isFetchingTasks?: boolean;
  filterOptions?: KanbanListFilterItem[];
  isOverDue?: boolean;
  isDemo?: boolean;
  onClickTask: (task: TaskItem) => void;
  onMoveTask: (task: TaskItem, sortIndex?: number) => void;
  className?: string;
  style?: CSSProperties;
}

const DEMO_TASK_PROJECT_NAME = 'Biz';

const KanbanList: React.FC<KanbanListProps> = memo(
  ({
    date,
    tasks,
    isFetchingTasks = false,
    onMoveTask,
    className,
    isOverDue,
    isDemo,
    filterOptions = [],
    onClickTask,
    style,
  }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const scrollPosition = useRef(0);
    const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);

    const { findProject, todoProjects } = useTodoTasksContext();
    const { findPhaseForTaskTime } = useCircadianContext();
    const { user } = useAuthContext();
    const { updateTodoTaskStatus } = useUpdateTaskStatus();
    const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

    const hasNoProjects = !todoProjects.length;

    useEffect(() => {
      if (listRef.current) {
        listRef.current.scrollTop = scrollPosition.current;
      }
    }, [tasks]);

    const { sortedTasks, lastSortIndexOfIncomplete } = useMemo(() => {
      if (isOverDue) {
        return { sortedTasks: tasks, lastSortIndexOfIncomplete: 0 };
      }

      // First sort by completed status
      const completedTasks = tasks.filter((t) => t.completed);
      const incompleteTasks = tasks.filter((t) => !t.completed);

      // Sort each group by sortIndex
      const sortByIndex = (tasks: TaskItem[]) => {
        return tasks.sort((a, b) => {
          if (a.sortIndex === undefined && b.sortIndex === undefined) return 0;
          if (a.sortIndex === undefined) return 1;
          if (b.sortIndex === undefined) return -1;
          return a.sortIndex - b.sortIndex;
        });
      };

      const sortedIncomplete = sortByIndex(incompleteTasks);
      const sortedCompleted = sortByIndex(completedTasks);
      const sortedTasks = [...sortedIncomplete, ...sortedCompleted];

      const lastSortIndexOfIncomplete =
        last(sortedIncomplete.filter((i) => !!i.sortIndex))?.sortIndex ?? 0;

      return { sortedTasks, lastSortIndexOfIncomplete };
    }, [tasks, isOverDue]);

    const storeScrollPosition = () => {
      if (listRef.current) {
        scrollPosition.current = listRef.current.scrollTop;
      }
    };

    const [{ isOver, canDrop }, drop] = useDrop(
      () => ({
        accept: 'TASK',
        drop: (item: TaskItem, monitor) => {
          if (monitor.didDrop() || dragOverIndex === null) {
            return;
          }
          const isDraggedToOtherDay =
            getDueDateYYYYMMDD(item.dueDateTime) !== date.format('YYYY-MM-DD');

          if (isDraggedToOtherDay) {
            const newSortIndex = calculateNewSortIndex(sortedTasks, dragOverIndex);
            onMoveTask(item, newSortIndex);
          } else {
            // Find the current index of the task in the sorted tasks
            const currentIndex = sortedTasks.findIndex((t) => t.taskId === item.taskId);

            // Only call onMoveTask if the position has changed and task is not completed
            // Since the dragged task is hidden, we need to compare with the adjusted dragOverIndex
            const isSamePosition =
              currentIndex === dragOverIndex || currentIndex === dragOverIndex - 1;

            if (
              !isSamePosition &&
              !item.completed &&
              !(currentIndex === 0 && dragOverIndex === 0)
            ) {
              const newSortIndex = calculateNewSortIndex(sortedTasks, dragOverIndex);
              onMoveTask(item, newSortIndex);
            }
          }
          setDragOverIndex(null);
        },
        hover: (item: TaskItem, monitor) => {
          if (!listRef.current) return;

          const clientOffset = monitor.getClientOffset();
          if (!clientOffset) return;

          const taskElements = listRef.current.getElementsByClassName('kanban-task-card');

          let newDragOverIndex = 0;
          let minDistance = Infinity;

          // Check if we're before the first task
          if (taskElements.length > 0) {
            const firstTaskRect = taskElements[0].getBoundingClientRect();
            const distanceToFirst = Math.abs(firstTaskRect.top - clientOffset.y);
            minDistance = distanceToFirst;
            newDragOverIndex = 0;
          }

          // Check distances to all task middle points
          for (let i = 0; i < taskElements.length; i++) {
            const taskElement = taskElements[i];
            const taskRect = taskElement.getBoundingClientRect();
            const taskMiddleY = taskRect.top + (taskRect.bottom - taskRect.top) / 2;
            const distance = Math.abs(taskMiddleY - clientOffset.y);

            if (distance < minDistance) {
              minDistance = distance;
              newDragOverIndex = i + 1; // +1 because we want to insert after this task
            }
          }

          // Check if we're after the last task
          if (taskElements.length > 0) {
            const lastTaskRect = taskElements[taskElements.length - 1].getBoundingClientRect();
            const distanceToLast = Math.abs(lastTaskRect.bottom - clientOffset.y);
            if (distanceToLast < minDistance) {
              newDragOverIndex = taskElements.length;
            }
          }

          if (newDragOverIndex !== dragOverIndex) {
            setDragOverIndex(newDragOverIndex);
          }
        },
        canDrop: (item) => !isOverDue,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }),
      [onMoveTask, dragOverIndex, sortedTasks]
    );

    const handleTaskComplete = async (taskId: string, boardId: string, completed: boolean) => {
      try {
        await updateTodoTaskStatus({
          taskId,
          boardId,
          completed,
          userId: user.userId,
          mutateParams: {
            date: date.format('YYYY-MM-DD'),
          },
        });
        setEventsSnackbar(`Task ${completed ? 'completed' : 'incompleted'}`);
      } catch (error) {
        setEventsSnackbar('Failed to update task status');
      }
    };

    return (
      <div ref={drop} className={clsx('kanban-list', className)} style={style}>
        <div className="kanban-list__container">
          <div className="kanban-list__header">
            <div className="kanban-list__header__left-group">
              <div className="kanban-list__header__day">
                {isOverDue ? 'Overdue' : date.format('dddd')}
              </div>
              <div className="kanban-list__header__date">
                {isOverDue && '- '}
                {date.format('MMM DD')}
              </div>
            </div>
            {!!filterOptions.length && <KanbanListFilterMenu filterOptions={filterOptions} />}
          </div>
          <div className="kanban-list__item-group" ref={listRef}>
            {isFetchingTasks && <CircularProgress className="kanban-list__loading" size={30} />}
            {!isFetchingTasks && (isDemo || !hasNoProjects) && (
              <div className="kanban-list__item-group__tasks">
                {dragOverIndex === 0 && isOver && canDrop && (
                  <div className="kanban-list__preview-card" />
                )}
                {sortedTasks
                  .filter((t) => !!t)
                  .map((task, index) => (
                    <React.Fragment key={task.taskId}>
                      <KanbanTaskCard
                        task={task}
                        phase={
                          task.startDateTime?.datetime && task.dueDateTime?.datetime
                            ? findPhaseForTaskTime(
                                task.startDateTime.datetime,
                                task.dueDateTime.datetime
                              )
                            : undefined
                        }
                        isOverDue={isOverDue}
                        projectName={
                          isDemo ? DEMO_TASK_PROJECT_NAME : findProject(task.boardId!)?.name
                        }
                        onClick={() => {
                          if (isDemo) return;
                          storeScrollPosition();
                          onClickTask(task);
                          trackEventMixpanel('open_task_detail_modal', {
                            taskId: task.taskId,
                          });
                        }}
                        onTaskComplete={(completed) => {
                          storeScrollPosition();
                          handleTaskComplete(task.taskId, task.boardId!, completed);
                        }}
                      />
                      {dragOverIndex === index + 1 && isOver && canDrop && (
                        <div className="kanban-list__preview-card" />
                      )}
                    </React.Fragment>
                  ))}
              </div>
            )}
            {!isFetchingTasks && !isOverDue && (
              <button
                className="kanban-list__add-task"
                onClick={() =>
                  onClickTask({
                    sortIndex: lastSortIndexOfIncomplete + SORT_INDEX_INCREMENT,
                    dueDateTime: {
                      date: dayjs(date).format('YYYY-MM-DD'),
                      timezone: dayjs.tz.guess(),
                    },
                  } as TaskItem)
                }
                disabled={isDemo}
              >
                <AddOutlined className="kanban-list__add-task__icon" />
                <p className="kanban-list__add-task__text">Add task</p>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default KanbanList;
