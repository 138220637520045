import { useQueries, useQueryClient } from '@tanstack/react-query';
import { TaskItem } from '@demind-inc/core';

import { tasksApi } from '../api';
import { TaskFilterOptions, TasksByDate } from '../types';
import { TASK_FILTER_ALL_LABEL_KEY } from '../constants';
import { useCallback } from 'react';

type TodoTaskFilter = Pick<TaskFilterOptions, 'onlyMyTask' | 'labelNames'>;
interface UseTodoTasksParams {
  todoTasksBoardIds: string[];
  dueDate: string;
  filter: TodoTaskFilter;
}

interface UseTodoTasksByDatesParams {
  todoTasksBoardIds: string[];
  dates: string[];
  filter?: TodoTaskFilter;
}

interface UseTodoTasksQueryParams {
  queryKey: ['lifestack.todo.tasks', UseTodoTasksParams];
}

export const useTodoTasks = ({ todoTasksBoardIds, dates, filter }: UseTodoTasksByDatesParams) => {
  const todoTasksQueries = useQueries({
    /* @ts-ignore */
    queries: dates.map((dueDate) => ({
      queryKey: ['lifestack.todo.tasks', { todoTasksBoardIds, dueDate, filter }],
      queryFn: ({ queryKey }: UseTodoTasksQueryParams) => {
        const [, { todoTasksBoardIds, dueDate, filter }] = queryKey;

        const transformedLabelNames =
          filter?.labelNames === undefined
            ? undefined
            : filter?.labelNames === TASK_FILTER_ALL_LABEL_KEY
              ? undefined
              : filter?.labelNames.join(',');

        return tasksApi
          .getTodoTasks(
            todoTasksBoardIds,
            dueDate,
            filter?.onlyMyTask,
            transformedLabelNames,
            false
          )
          .then(({ data }) => ({ date: dueDate, tasks: data as TaskItem[] }) as TasksByDate);
      },
      refetchOnWindowFocus: false,
      enabled: !!todoTasksBoardIds.length,
      staleTime: 15000, // 15s
    })),
  });

  return {
    todoTasksByDate: todoTasksQueries.map((query) => query.data ?? []).flat(),
    isLoading: todoTasksQueries.some((query) => query.isLoading),
    ...todoTasksQueries,
  };
};

export const useTodoTasksCache = () => {
  const queryClient = useQueryClient();

  const findInCache = useCallback(
    ({
      todoTasksBoardIds,
      dueDate,
      filter,
    }: {
      todoTasksBoardIds: string[];
      dueDate: string;
      filter?: TodoTaskFilter;
    }) => {
      const tasks = queryClient.getQueryData<TasksByDate[]>([
        'lifestack.todo.tasks',
        { todoTasksBoardIds, dueDate, filter },
      ]);

      return tasks ? tasks.filter((item) => !!item) : [];
    },
    [queryClient]
  );

  const findInCacheByDate = useCallback(
    (dueDate: string, projectId?: string) => {
      // Get all queries from the cache
      const queries = queryClient.getQueriesData<TasksByDate>({
        queryKey: ['lifestack.todo.tasks'],
      });

      // Find all matching queries for the given date
      const matchingQueries = queries
        .filter(([queryKey]) => {
          const [_, params] = queryKey as ['lifestack.todo.tasks', UseTodoTasksParams];
          return (
            params.dueDate === dueDate &&
            params.todoTasksBoardIds.length > 0 &&
            (projectId ? params.todoTasksBoardIds.includes(projectId) : true)
          );
        })
        .map(([_, data]) => data);

      // If no matching queries found, return empty array
      if (matchingQueries.length === 0) {
        return [];
      }

      // Get the most recent query data
      const latestData = matchingQueries[matchingQueries.length - 1];
      return latestData?.tasks?.filter((item) => !!item) ?? [];
    },
    [queryClient]
  );

  return { findInCache, findInCacheByDate };
};
