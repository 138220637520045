import { CircadianPhase, TaskItem } from '@demind-inc/core';
import { FC, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { LocalOfferOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import clsx from 'clsx';

import './KanbanTaskCard.scss';
import { getCssVariable } from '../../../utils';
import { circadianPhaseInfo } from '../../../data-access';
import { useGeneralSettings } from '../../../hooks';
import { formatTaskTimeRange, getFormattedOverdueTime } from '../../../helpers';
import { TODO_INTEGRATION_ICONS } from '../../TodoIntegration';
import { TaskCheckBox } from '../../common/';

interface KanbanTaskCardProps {
  task: TaskItem;
  phase?: CircadianPhase;
  projectName?: string;
  isOverDue?: boolean;
  onTaskComplete?: (completed: boolean) => void;
  onClick?: () => void;
}

const taskIncompleteColor = getCssVariable('--color-gray-base');
const taskCompleteColor = getCssVariable('--color-primary');
const grayLightest = getCssVariable('--color-gray-lightest');
const TASK_EXPANDED_LABEL_COUNT = 2;

const KanbanTaskCard: FC<KanbanTaskCardProps> = ({
  task,
  projectName,
  phase,
  isOverDue = false,
  onTaskComplete = () => void 0,
  onClick = () => void 0,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'TASK',
      item: task,
      canDrag: () => !task.completed,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [task]
  );

  const taskTime = formatTaskTimeRange({
    startDatetime: task.startDateTime?.datetime ? dayjs(task.startDateTime.datetime) : undefined,
    endDatetime: task.dueDateTime?.datetime ? dayjs(task.dueDateTime.datetime) : undefined,
    timeFormat,
  });

  const taskPhaseInfo = useMemo(() => (phase ? circadianPhaseInfo[phase] : undefined), [phase]);

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="kanban-task-card"
      onClick={onClick}
      key={task.taskId}
    >
      <div className="kanban-task-card__top-group">
        {isOverDue ? (
          <div className="kanban-task-card__top-group__overdue">
            {getFormattedOverdueTime(task, timeFormat)}
          </div>
        ) : (
          <div className="kanban-task-card__top-group__time">
            {taskTime ? (
              <div
                className="kanban-task-card__time"
                style={{ borderColor: taskPhaseInfo?.color, color: taskPhaseInfo?.color }}
              >
                {taskTime}
              </div>
            ) : (
              <div className="kanban-task-card__time">Drag to set time</div>
            )}
            {taskPhaseInfo?.label && (
              <div
                className="kanban-task-card__phase"
                style={{ backgroundColor: taskPhaseInfo?.color }}
              >
                {taskPhaseInfo.label}
              </div>
            )}
          </div>
        )}
        <div className="kanban-task-card__project">
          <img
            src={TODO_INTEGRATION_ICONS[task.appFrom]}
            alt={task.appFrom}
            className="kanban-task-card__project__icon"
          />
          <p className="kanban-task-card__project__text">{projectName}</p>
        </div>
      </div>
      <div className="kanban-task-card__bottom-group">
        <TaskCheckBox
          isCompleted={task.completed}
          onChange={onTaskComplete}
          size={18}
          color={task.completed ? taskCompleteColor : taskIncompleteColor}
          className="kanban-task-card__bottom-group__status"
        />
        <div>
          <span
            className={clsx('kanban-task-card__title', {
              'kanban-task-card__title--done': task.completed,
            })}
          >
            {task.name}
          </span>
          {!!task.labels?.length && (
            <div className="kanban-task-card__labels">
              {task.labels.slice(0, TASK_EXPANDED_LABEL_COUNT).map((label) => (
                <div
                  key={`${task.taskId}-${label.name}`}
                  className="kanban-task-card__labels__item"
                  style={{ backgroundColor: label.color ? `${label.color}33` : grayLightest }}
                  id={label.name}
                >
                  <LocalOfferOutlined className="kanban-task-card__labels__item__icon" />
                  <div className="kanban-task-card__labels__item__name">
                    {label.name || '(No name)'}
                  </div>
                </div>
              ))}
              {task.labels.length > TASK_EXPANDED_LABEL_COUNT && (
                <div
                  className="kanban-task-card__labels__item"
                  style={{ backgroundColor: grayLightest }}
                >
                  <LocalOfferOutlined className="kanban-task-card__labels__item__icon" />
                  <div className="kanban-task-card__labels__item__name">
                    +{task.labels.length - TASK_EXPANDED_LABEL_COUNT}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KanbanTaskCard;
