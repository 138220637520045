import { TaskItem } from '@demind-inc/core';

export const SORT_INDEX_INCREMENT = 10000;
export const INITIAL_SORT_INDEX = 100000;

export const calculateNewSortIndex = (tasks: TaskItem[], targetIndex: number): number => {
  // If no tasks, start with initial sort index
  if (tasks.length === 0) {
    return INITIAL_SORT_INDEX;
  }

  // If target is at the start
  if (targetIndex === 0) {
    const firstTask = tasks[0];
    const firstSortIndex = firstTask.sortIndex ?? INITIAL_SORT_INDEX;
    return Math.max(0, firstSortIndex - SORT_INDEX_INCREMENT);
  }

  // If target is at the end
  if (targetIndex >= tasks.length) {
    const lastTask = tasks[tasks.length - 1];
    const lastSortIndex =
      lastTask.sortIndex ?? INITIAL_SORT_INDEX + SORT_INDEX_INCREMENT * (tasks.length - 1);
    return lastSortIndex + SORT_INDEX_INCREMENT;
  }

  // Get surrounding tasks
  const prevTask = tasks[targetIndex - 1];
  const nextTask = tasks[targetIndex];

  // If either task doesn't have a sort index, assign new ones
  if (!prevTask.sortIndex || !nextTask.sortIndex) {
    const prevIndex =
      prevTask.sortIndex ?? INITIAL_SORT_INDEX + SORT_INDEX_INCREMENT * (targetIndex - 1);
    const nextIndex = nextTask.sortIndex ?? INITIAL_SORT_INDEX + SORT_INDEX_INCREMENT * targetIndex;
    return Math.floor((prevIndex + nextIndex) / 2);
  }

  // Calculate new sort index between surrounding tasks
  return Math.floor((prevTask.sortIndex + nextTask.sortIndex) / 2);
};

export const normalizeSortIndices = (tasks: TaskItem[]): TaskItem[] => {
  if (tasks.length === 0) return tasks;

  return tasks.map((task, index) => ({
    ...task,
    sortIndex: INITIAL_SORT_INDEX + SORT_INDEX_INCREMENT * index,
  }));
};
