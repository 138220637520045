import { useMutation, useQueryClient } from '@tanstack/react-query';

import { metricsApi } from '../api';
import { MealDetailsByDate } from '@demind-inc/core';

interface UseSyncMealDetailsParams {
  metricId: string;
  terraUserIds: string[];
  startDate: string;
  endDate: string;
}

export function useSyncMealDetails() {
  const queryClient = useQueryClient();

  const syncMealDetailMutation = useMutation({
    mutationFn: ({ metricId, terraUserIds, startDate, endDate }: UseSyncMealDetailsParams) => {
      return metricsApi
        .syncMeal(metricId, { terraUserIds, startDate, endDate })
        .then((data) => (data.data.results ?? []) as MealDetailsByDate[]);
    },
    onSuccess: (data) => {
      if (!data.length) {
        return;
      }

      queryClient.invalidateQueries({ queryKey: ['lifestack.meal.details'] });
    },
  });

  return {
    syncMealDetail: syncMealDetailMutation.mutateAsync,
    ...syncMealDetailMutation,
  };
}
