import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SleepDetailsByDate } from '@demind-inc/core';

import { metricsApi } from '../api';

interface UseUpdateSleepDetailsParams {
  userId: string;
  metricId: string;
  detailsByDate: SleepDetailsByDate[];
}

export function useUpdateSleepDetails() {
  const queryClient = useQueryClient();

  const updateSleepDetailMutation = useMutation({
    mutationFn: ({ userId, metricId, detailsByDate }: UseUpdateSleepDetailsParams) => {
      return metricsApi.updateSleepDetails(metricId, userId, detailsByDate);
    },
    onSuccess: (_, { detailsByDate }) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.sleep.details'] });

      detailsByDate.forEach((item) => {
        const date = item.date;
        if (item.details) {
          queryClient.invalidateQueries({ queryKey: ['lifestack.circadian', { date }] });
        }
      });
    },
  });

  return {
    updateSleepDetail: updateSleepDetailMutation.mutateAsync,
    ...updateSleepDetailMutation,
  };
}
